// RegistrationData is a collection of all of the state
// needed during the registration and verification process
// as well as any identifying information for interacting with 
// the backend

const RegistrationData = {}
RegistrationData.canRegister = false
RegistrationData.verificationCodeFromServer = '' 
RegistrationData.verificationCodeFromClient = '' 
RegistrationData.userAndDepartmentAccepted = false
RegistrationData.loaded = false 


RegistrationData.clearSession = () => {
    RegistrationData.canRegister = false
    RegistrationData.verificationCodeFromServer = '' 
    RegistrationData.verificationCodeFromClient = '' 
    RegistrationData.userAndDepartmentAccepted = false
    RegistrationData.loaded = false 
}

export default RegistrationData