// SessionData is intended to hold all of the data that 
// is shared at runtime by more than one screen.  

const SessionData = {}
SessionData.serviceData                 = {}  // cache service data for showing details
SessionData.selectedServices            = []  // currently selected services
SessionData.openCategories              = []  // categories currently displaying their services
SessionData.screenHistory               = []  // sequence of screens shown, used for providing the <- button in header
SessionData.updateFindServicesPage      = {}  // set by FindService()
SessionData.sendNotesUiDescriptor       = {}  // UI details for the various note capturing screens based on SendNoteScreen
SessionData.sendNotesReportObject       = {}  // User's data to post to the server then process as appropriate
SessionData.sendingNote                 = false  // true while a note is being sent to the server
SessionData.resetting                   = false  // true while resetting the state of the app
SessionData.loadServicesFailed          = false  // set to tru if load fails 


SessionData.clearSession = () => {
    SessionData.serviceData = {}
    SessionData.selectedServices = []
    SessionData.openCategories = []
    SessionData.screenHistory = []
    SessionData.updateFindServicesPage = {}
    SessionData.sendNotesUiDescriptor = {} 
    SessionData.sendNotesReportObject = {}
    SessionData.sendingNote = false
    SessionData.resetting = false 
    SessionData.loadServicesFailed = false
}

SessionData.selectionCount = (services) => {
    let ret = 0
    if (undefined===services){
        return ret
    }
    services.forEach((service) => {
        if (SessionData.itemInArr(service.serviceKey, SessionData.selectedServices))
        {
            ret++
        }
    })
    return ret
}

SessionData.itemInArr = (item, arr) => {
    return (-1 !== arr.indexOf(item))
}

SessionData.ArrAsString = (arr) => {
    let ret = ''
    arr.forEach((item) => {ret += (item + ',')} )
    return '[' + ret + ']'
}

export default SessionData