import React from 'react';
import SfyHeader from "./SfyHeader"
import Utils from "../Utilities/Utils"
import { Grid, Box, } from '@material-ui/core';

// Logos
import SfyLogo from './images/SFY_logo.png'
import TCSD from "./images/TippecanoeSheriff'sDepartment47904tippecanoe.in.govBadge.png"
import LIPD from "./images/LafayettePoliceDepartment47901lafayette.in.govBadge.png"
import WLIPD from "./images/WestLafayettePoliceDepartment47906westlafayette.in.govBadge.png"

//global data
import SettingsData from "./SettingsData";
import RegistrationData from "./RegistrationData";
import ClientData from "../Services/ClientData";

function FinishBadgeScreen(setScreen) {
    var verified = SettingsData.verified
    RegistrationData.loaded = true;
    setScreen(verified?'find-services':'register-screen')
}

export default class BadgeScreen extends React.Component {
    state = {
        loadingCategories: true,
        loadingServices: true,
    }

    async componentDidMount() {
        ClientData.initialize()
    }

    render () {
        setTimeout(() => {FinishBadgeScreen(this.props.setScreen)}, 5000);
        let badge = ""
        let pd = SettingsData.policeDepartment
        let agencyNickname = Utils.getUniqueAgencyId(pd)

        switch(agencyNickname) {
            case "TippecanoeSheriff'sDepartment47904tippecanoe.in.gov":
                badge = TCSD
                break;
            case "LafayettePoliceDepartment47901lafayette.in.gov":
                badge = LIPD
                break;
            case "WestLafayettePoliceDepartment47906westlafayette.in.gov":
                badge = WLIPD
                break;
            default:
                console.log("agency badge could not be found.")
        }
        //console.log("nickname: " + agencyNickname + "badge: " + badge)

        return ( 
            <Grid container direction='column' spacing={3}>
                <Grid container direction='column'  alignItems='stretch' >
                    <SfyHeader screenId={'badge-screen'} setScreen={this.props.setScreen} Settings={false} />
                </Grid >
                <Grid container direction='column' justify="center" alignItems="center">
                    <Box  mt={3}>
                        <Grid item xs={12} sm={6}>
                            <img src={SfyLogo} alt='SFY Logo' />
                        </Grid>
                    </Box>
                    <Box mt={4}>
                        <Grid item xs={12} sm={6}>
                            <img src={badge} alt={"BADGE"}/>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>   
        )
    }
}
