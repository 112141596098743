import React from "react"
import {ListItem, ListItemIcon, Checkbox, ListItemText , Typography, Grid, } from '@material-ui/core';
import Format from "../Utilities/Format"

// global data
import SessionData from './SessionData';
import UpdatingData from "../Services/UpdatingData";

export default class ServiceItem extends React.Component {
  state = {
    ignore: true,
  }

  constructor(props) {
    super()
    this.serviceData =props.serviceData
    this.serviceKey =props.serviceKey
    this.organization = this.serviceData.organization
    this.labelId = "checkbox-list-label-" + this.serviceKey

    this.setScreen = props.setScreen
    this.handleToggle = this.handleToggle.bind(this)
    this.showServiceDetails = this.showServiceDetails.bind(this)
    this.updateDistances = this.updateDistances.bind(this)
    this.isChecked = false
    this.service = "not set"
    this.long = UpdatingData.currentLongitude
    this.lat = UpdatingData.currentLatitude
    //this.timedIterator = setInterval(this.updateDistances, 10000)
  }

  handleToggle = () => {
    let serviceIndex = SessionData.selectedServices.indexOf(this.serviceKey)
    // '+serviceIndex+', key '+this.serviceKey)
    if (-1 ===  serviceIndex) {
      // adding a selection
      SessionData.selectedServices.push(this.serviceKey)
    }
    else {
      // removing a selection
      SessionData.selectedServices.splice(serviceIndex,1)
    }
    //console.log("selectedServices" + JSON.stringify(SessionData.selectedServices,null,3))
    this.setState({ignore: !this.state.ignore})
    SessionData.updateFindServicesPage()
  }

  showServiceDetails = (serviceData) => () => {
    SessionData.serviceData = serviceData
    this.setScreen('service-detail-screen')
  }

  updateDistances = () => {
    //console.log("updateDistances")
    let longLatChange = Math.abs(this.long - UpdatingData.currentLongitude) + Math.abs(this.lat - UpdatingData.currentLatitude)
    if (.001 < longLatChange ) {
      //console.log("updateDistances internal ["+ Math.abs(this.long - ServicesData.currentLongitude) +","+Math.abs(this.lat - ServicesData.currentLatitude)+"]" -- change= "+longLatChange)
      console.log("longlat changed." + longLatChange)
      this.long = UpdatingData.currentLongitude
      this.lat = UpdatingData.currentLatitude
      this.setState({ignore: !this.state.ignore})
    }
  }

  render () {
    let remote = ("true" === this.serviceData.remote)
    this.service = this.serviceData.service + " " + Format.distance(this.serviceData["currentDistance"],remote)
    this.isChecked = SessionData.itemInArr(this.serviceKey, SessionData.selectedServices)
    //console.log("SI.render()-- service: " + this.service + ", isChecked: " +this.isChecked)
    return (
      <ListItem alignItems="flex-start" key={this.serviceKey} role={undefined} dense  > 
        <ListItemIcon>
          <Checkbox
            onClick={this.handleToggle}
            edge="start"
            color="primary"
            tabIndex={this.serviceKey}
            checked={this.isChecked}
            inputProps={{ 'aria-labelledby': this.labelId }}
          />
        </ListItemIcon>
        <ListItemText
          onClick={this.showServiceDetails(this.serviceData)}
          secondary= {
            <React.Fragment>
              <Grid component={'span'} container direction='column'>
                <Typography component={'span'} variant="body1" color="secondary" >
                    {this.service}
                </Typography>
                <Typography component={'span'} variant="body2" color="secondary" >
                  {'by ' + this.organization}
                </Typography>
              </Grid>
            </React.Fragment>
          }
        />
      </ListItem>
    )
  }
}