import React from "react"
import { withStyles } from "@material-ui/core/styles";
import { Box, AppBar,Toolbar, Typography, IconButton, Icon, Grid, } from '@material-ui/core';
import SettingsData from "./SettingsData";

function getScreenName(screenId) {
    //alert('getScreenName:('+screenId+')' )
    var demoMode = ("true"===window.localStorage.getItem('demoUser'))
    if (demoMode!=SettingsData.inDemoMode) {
        console.log("SfyHeader.getScreenName--unexpectedlydemoMode: "+demoMode + "!== SD.inDemoMode: "+SettingsData.inDemoMode)
    }      

    var loadingText = demoMode?"Loading Demo ...":"Loading ..." 
    var registerText = demoMode?"Register Demo":"Register"
    var verifyText = demoMode?"Verify Demo":"Verify"
    var accountText = demoMode?"Demo Account":"Account"
    
    var ret = ''
    switch(screenId) 
    {
        case 'register-screen':
            ret = registerText
            break;
        case 'register-error-screen':
            ret = 'Registration Error'
            break;
        case 'verification-screen':
            ret = verifyText
            break;
        case 'verification-error-screen':
            ret = 'Verification Error'
            break;
        case 'session-error-screen':
            ret = 'Session Error'
            break;
        case 'find-services':
            ret = 'Find Services'
            break;
        case 'badge-screen':
            ret = loadingText
            break;
        case 'settings-screen':
            ret = accountText
            break;
        case 'service-detail-screen':
            ret = 'Service Info'
            break;
        case 'diversion-options-screen':
            ret = 'Diversion Options'
            break;
        case 'diversion-contact-screen':
            ret = 'Contact Information'
            break;
        case 'add-contact-screen':
            ret = 'Add Contact'
            break;
        case 'diversion-send-screen':
            ret = 'Request Diversion'
            break;
        case 'add-note-screen':
            ret = 'Submit a Note'
            break;
        case 'send-suggestion-screen':
            ret = 'Make a Suggestion'
            break;
        case 'report-bug-screen':
            ret = 'Report a Problem'
            break;
        case 'send-feature-request-screen':
            ret = 'Request a Feature'
            break;
        case 'send-services':
            ret = 'Send Services'
            break;
        default:
            ret = 'Missing Screen: |'+screenId+'|'
            break;         
    }
    return ret;
}

function addSettingsButtonConditionally (props){
    if (props.Settings){
        return (
            <Grid mr={4}>
                <IconButton 
                    edge="end"
                    aria-label="settings" 
                    onClick={() => { 
                        //alert('To Settings') 
                        props.setScreen('settings-screen') 
                    }}
                    style={{ color: 'white'}}
                >
                    <Icon fontSize="large">person_outlined</Icon> 
                </IconButton>
                {/* */}
            </Grid>
        )
    }
    else {        
        return (
            <Grid mr={4}></Grid>
        )
    }
}

function addBackButtonConditionally (props) {   
    let forward =  ("find-services"=== props.screenId)
    //console.log((forward?">":"<") + " was: "+props.lastScreenId+" is: "+props.screenId)
    let addBackButton = (props.lastScreenId && (0 !== props.lastScreenId.length)) 
    let priorScreenName = addBackButton ? getScreenName(props.lastScreenId) : null
    if (addBackButton){
        if (forward) {
            return (
                <React.Fragment>
                    <Box mt={2} mb={0} style={{  backgroundColor: '#ffffff' , height: '7vh' }} >
                        <IconButton
                            onClick={() => { 
                                //alert('Go to: ' + priorScreenName); 
                                props.setScreen(props.lastScreenId) 
                            }}
                            style={{ color: '#12ABF7'}}
                        >
                            {priorScreenName}
                             <Icon>east</Icon>   
                        </IconButton>
                    </Box>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Box mt={2} mb={0} style={{  backgroundColor: '#ffffff' , height: '7vh' }} >
                        <IconButton
                            onClick={() => { 
                                //alert('Go to: ' + priorScreenName); 
                                props.setScreen(props.lastScreenId) 
                            }}
                            style={{ color: '#12ABF7'}}
                        >
                             <Icon>keyboard_backspace</Icon>   
                             {priorScreenName}
                        </IconButton>
                    </Box>
                </React.Fragment>
            )   
        }  
    }
    else {
        return (
            <div></div>
        )
    }
}

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);
  
export default function SfyHeader(props)
{
    let currentScreenName = getScreenName(props.screenId) 
    return (
        <div>
            <AppBar position="static"> 
                <Toolbar variant='dense' >
                    {/*<Typography component={'span'} variant="subtitle1" color='secondary' >
                        {props.context}
                    </Typography>*/}
                </Toolbar>
                <Toolbar >
                    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                        <WhiteTextTypography component={'span'} variant="h4">
                            { currentScreenName }
                        </WhiteTextTypography>
                        {addSettingsButtonConditionally (props)}
                    </Grid>
                </Toolbar>
                {addBackButtonConditionally (props)}
            </AppBar>
        </div>
    );
}                