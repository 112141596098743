import React from 'react';
import SfyHeader from "./SfyHeader"
import Format from '../Utilities/Format';
import { Grid, TextField, Fab, Box, Icon } from '@material-ui/core';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// global data
import DiversionData  from './DiversionData';

export default class DiversionContactScreen extends React.Component {
    state = {
        firstName:DiversionData.DivertedFirstName,
        lastName:DiversionData.DivertedLastName,
        youthName:DiversionData.YouthName,
        youthNameExists:DiversionData.HaveYouthName,
        doB:DiversionData.DivertedDoB,
        dobPlaceholder:"",
        name1:DiversionData.Contact1Name,
        phone1:DiversionData.Contact1Phone,
        email1:DiversionData.Contact1Email,
        note1:DiversionData.Contact1Note,
        rerender:false,
        canMakeContact:DiversionData.CanContact,
    }
    constructor(props){
        super()
        this.changeFirstName = this.changeFirstName.bind(this)
        this.changeLastName = this.changeLastName.bind(this)
        this.changeDoB = this.changeDoB.bind(this)

        this.changeName1 = this.changeName1.bind(this)
        this.changePhone1 = this.changePhone1.bind(this)
        this.changeEmail1 = this.changeEmail1.bind(this)
        this.changeNote1 = this.changeNote1.bind(this)

        this.updateCanMakeContact = this.updateCanMakeContact.bind(this)
        this.updateYouthNameExists = this.updateYouthNameExists.bind(this)
        this.goToNextScreen = this.goToNextScreen.bind(this)
        this.showNext = this.showNext.bind(this)
        this.showAddContact = this.showAddContact.bind(this) 
        // this. = this..bind(this)
    }

    async componentDidMount() {
        let dateRange = Format.calculateDobPlaceholder()
        //console.log("DR: " + dateRange)
        this.setState({dobPlaceholder:dateRange})
    }

    goToNextScreen (nextScreenName) {
        //console.log("DCS.goToNextScreen--valid phone: "+DiversionData.HaveValid1Phone+ ", valid email: " +DiversionData.HaveValid1Email+ ", valid DOB: " + DiversionData.HaveValidDoB )
        DiversionData.IgnoreContact2 = false
        DiversionData.IgnoreContact3 = false
        let message = ""
        let blocked = false
        let transition = true
        let validDoB = DiversionData.HaveValidDoB
        if (false === validDoB) {
            if (0<DiversionData.NonExistentDate.length) {
                message += "The date '"+DiversionData.NonExistentDate+"' does not occur on the calendar. "
            }
            else {
                message += "Please enter the DOB numerically as month/day/year. For example: 1/23/2014. "
            }

            blocked = true
        }
      
        if (undefined === validDoB) {
            message += DiversionData.DivertedDoB + " does not fall into the range defined as \"youth\". "
            blocked = true
        }
        if (!DiversionData.HaveValid1Phone) {
            message += "Please correct the contact's required phone number--it must have all 10 digits. "
            blocked = true
        }
        if (!DiversionData.HaveValid1Email) {
            message += "The contact's optional email is not valid--it will be ignored. "
        }
        if (10 < message.length) {
            if (blocked) {
                alert(message)
                transition= false
            }
            else { 
                message += "Cancel to fix things before proceeding. "
                transition = window.confirm(message)
            }
        }
        
        if(DiversionData.YouthIsContact.includes(1)) {
            message += "The youth cannot also be the required contact. Please correct that. "
            alert(message)
            transition= false
        }

        if (transition) {
            this.props.setScreen(nextScreenName)
        }
    }
    showNext () {
        this.goToNextScreen ('diversion-send-screen') 
    }
    showAddContact () {
        this.goToNextScreen ('add-contact-screen')
    }
    
    changeFirstName (event) { 
        this.setState({firstName:event.target.value})
        DiversionData.DivertedFirstName = event.target.value
        this.updateYouthNameExists()
        this.updateCanMakeContact()
        Format.setYouthContactMap()
    }
    changeLastName (event) { 
        this.setState({lastName:event.target.value}) 
        DiversionData.DivertedLastName = event.target.value
        this.updateYouthNameExists()
        this.updateCanMakeContact()
        Format.setYouthContactMap()
    }
    changeDoB (event) { 
        DiversionData.NonExistentDate = ""
        this.setState({doB:event.target.value})
        DiversionData.DivertedDoB = event.target.value
        this.updateCanMakeContact()
        DiversionData.HaveValidDoB = Format.validDoB (DiversionData.DivertedDoB)
    }
    changeName1 (event) {
        //console.log("changeName1 " + event.target.value)
        DiversionData.Contact1Name = event.target.value
        this.setState({name1:event.target.value})
        this.updateCanMakeContact()
        Format.setYouthContactMap()
    }
    changePhone1 (event) {
        //console.log("changePhone1 " + event.target.value)
        DiversionData.Contact1Phone = event.target.value
        this.setState({phone1:event.target.value})
        this.updateCanMakeContact()
        DiversionData.HaveValid1Phone = Format.validPhoneNumber(DiversionData.Contact1Phone)
        //console.log("changePhone1--valid: " + DiversionData.HaveValid1Phone)
    }
    changeEmail1 (event) {
        //console.log("changeEmail1 " + event.target.value)
        DiversionData.Contact1Email = event.target.value
        DiversionData.HaveValid1Email = (0===DiversionData.Contact1Email.length) || Format.validEmailAddress(DiversionData.Contact1Email)
        //console.log("changeEmail1--valid: " + DiversionData.HaveValid1Email + ", " + DiversionData.Contact1Email)
    }
    changeNote1 (event) {
        //console.log("changeNote1 " + event.target.value)
        DiversionData.Contact1Note = event.target.value
    }
    updateYouthNameExists () {
        let haveYN = ((1 < DiversionData.DivertedFirstName.length) &&
                      (1 < DiversionData.DivertedLastName.length))
                      DiversionData.HaveYouthName = haveYN  
        this.setState({youthNameExists:haveYN})
        let YN = ""
        if (haveYN) {
            YN = DiversionData.DivertedFirstName + " " + DiversionData.DivertedLastName
            DiversionData.YouthName = YN
            this.setState({youthName:YN})
        }
        //console.log("updateYouthNameExists--yne: " + DiversionData.HaveYouthName + ", yn: |" + DiversionData.YouthName +"|")
    }
    
    updateCanMakeContact () {
        let dfn = (1 <  DiversionData.DivertedFirstName.length)
        let dln = (1 <  DiversionData.DivertedLastName.length)
        let doB = (3 < DiversionData.DivertedDoB.length)
        let c1n = (1 <  DiversionData.Contact1Name.length)
        let c1p = (3 < DiversionData.Contact1Phone.length)
        let cmc = dfn && dln  && doB && c1n && c1p
        this.setState({canMakeContact:cmc})
        DiversionData.CanContact = cmc
    }

    render () {
        //console.log("DCS render")
        return ( 
            <div>
                <SfyHeader context='SFY' screenId={'diversion-contact-screen'}  lastScreenId={this.props.lastScreenId}  setScreen={this.props.setScreen}  Settings={true} />
                <div>
                    <Box ml={3} mr={3}>
                        <Grid container direction='column' spacing={1}>
                            <Box mb={-2}> 
                                <p style={{color:'#367BBD'}}>Identify the Youth:</p>
                            </Box>   
                            <Box mr={0}> 
                                <TextField
                                    id="youth_first_name"
                                    placeholder={"First Name"}
                                    defaultValue={this.state.firstName}
                                    fullWidth={true}
                                    color='primary' 
                                    size="small" 
                                    margin='dense'
                                    variant='outlined' 
                                    onChange ={this.changeFirstName}                        
                                />
                                <TextField
                                    id="youth_last_name"
                                    placeholder={"Last Name"}
                                    defaultValue={this.state.lastName}
                                    fullWidth={true}
                                    color='primary'  
                                    size="small"
                                    margin='dense'
                                    variant='outlined' 
                                    onChange ={this.changeLastName} 
                                />
                                <TextField
                                    id="youth_dob"
                                    placeholder={this.state.dobPlaceholder}
                                    defaultValue={this.state.doB}
                                    fullWidth={true}
                                    color='primary'  
                                    size="small"
                                    margin='dense'
                                    variant='outlined' 
                                    onChange ={this.changeDoB}                      
                                />
                            </Box>  
                            <Box mb={-2}> 
                                <p style={{color:'#367BBD'}}>Required Guardian/Caretaker Contact:</p>
                            </Box> 
                            <Box mr={0}> 
                                <TextField
                                    id="primary_contact_name"
                                    placeholder={"Required Full Name"}
                                    defaultValue={this.state.name1}
                                    fullWidth={true}
                                    color='primary'
                                    margin='dense'
                                    size="small" 
                                    variant='outlined'
                                    onChange ={this.changeName1}                      
                                />
                                <TextField
                                    id="primary_contact_phone"
                                    placeholder={"Required Phone"}
                                    defaultValue={this.state.phone1}
                                    fullWidth={true}
                                    color='primary' 
                                    margin='dense'
                                    size="small"
                                    variant='outlined'
                                    onChange ={this.changePhone1}                     
                                />
                                    <TextField
                                    id="primary_contact_email"
                                    placeholder={"Optional Email"}
                                    defaultValue={this.state.email1}
                                    fullWidth={true}
                                    color='primary'  
                                    margin='dense'
                                    size="small"
                                    variant='outlined'
                                    onChange ={this.changeEmail1}            
                                />
                                <TextField
                                    id="primary_contact_note"
                                    placeholder={"Optional Note"}
                                    defaultValue={this.state.note1}
                                    fullWidth={true}
                                    color='primary'
                                    multiline
                                    minRows={2} 
                                    margin='dense'
                                    size="small" 
                                    variant='outlined'
                                    onChange ={this.changeNote1} 
                                />
                            </Box>  
                        </Grid>
                    </Box>                
                    <Box ml={2} mt={2}> 
                        <Fab color='secondary' variant="extended" disabled={!this.state.canMakeContact} onClick={this.showAddContact}>
                            Add Contact
                            <Icon>arrow_forward</Icon> 
                        </Fab>
                    </Box>
                    <Box ml={2} mt={2}> 
                        <Fab color='secondary' variant="extended" disabled={!this.state.canMakeContact} onClick={this.showNext}>
                            Review & Send
                            <Icon>arrow_forward</Icon> 
                        </Fab>
                    </Box>
                </div>
            </div>
        )
    }
}