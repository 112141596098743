import React from 'react';
import SfyHeader from "./SfyHeader"
import SwitchScreen from "./SwitchScreen";
import { Box, Grid } from '@material-ui/core';

// Global Data
import RegistrationData from "./RegistrationData";
import SettingsData from "./SettingsData";

class VerificationErrorScreen extends React.Component
{
    state = {
        checkVerificationCode: (RegistrationData.userAndDepartmentAccepted && !SettingsData.verified),
        loadingVerificationCode: false,
        checkFailed: false,
        mounted: false,
        errorMessage: ""
    }

    constructor(props) {
        super()
    }

    async componentDidMount() {
        //console.log("##### accepted: "+RegistrationData.userAndDepartmentAccepted +",  verified: "+SettingsData.verified)
        // came from the Verification screen 
        if (this.state.checkVerificationCode) {
            console.log("In checkVerificationCode--server: " +RegistrationData.verificationCodeFromServer + ", client: " +RegistrationData.verificationCodeFromClient)
            SettingsData.verified = (RegistrationData.verificationCodeFromServer === RegistrationData.verificationCodeFromClient )
            window.localStorage.setItem('verified', "true")
            this.setState({checkFailed: !SettingsData.verified})
            if (!SettingsData.verified) {
                let errorMessage = "The verification code you provided ("+RegistrationData.verificationCodeFromClient+") does not match the one on the server.  Please try again."
                this.setState({errorMessage: errorMessage}) 
            }
            else {
                SettingsData.getFirstSessionKey = true
                this.setState({checkSessionKey: true}) 
            }
            let nextscreen = SettingsData.verified ?"session-error-screen":"verification-screen"
            this.setState({nextScreen: nextscreen})
        }
        this.setState({mounted: true})
    }

    render () {
        //console.log("ES.render() " + this.state.nextScreen )
        if (!this.state.mounted)
        {
            return <div></div>
        }
        // if the current check failed, display the ErrorScreen with appropriate info, otherwise switch to the next screen 
        if (this.state.checkFailed && this.state.checkVerificationCode) {
            console.log("In ErrorScreen.render() checkVerificationCode with error: " + this.state.errorMessage)
            return (
                <Grid container direction='column' spacing={3}>
                    <Grid>
                        <SfyHeader screenId={'register-error-screen'}  lastScreenId={'register-screen'} setScreen={this.props.setScreen}  Settings={false} />
                    </Grid>
                    <Box ml={3} mt={4} mr={3} >
                        <Grid item>
                            {this.state.errorMessage}
                        </Grid>
                    </Box>
                </Grid>
            )
        }
        else {
            //console.log("ERROR SCREEN CHECK OKAY" + this.state.nextScreen )
            return (
                <div>
                    <SwitchScreen screen={this.state.nextScreen} setScreen={this.props.setScreen} />
                </div>
            )
        }
    }
}

export default VerificationErrorScreen
