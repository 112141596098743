import React from 'react';
import SfyHeader from "./SfyHeader"
import { Grid, TextField , Button, Box, Paper, Typography } from '@material-ui/core';

// global data
import SessionData from './SessionData';

export default function SendNoteScreen(props) {
    //const classes = useStyles();
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [noteText, setNoteText] = React.useState('');
    const onCodeChange = event => {
        let text = event.target.value
        setNoteText(text);
        setCanSubmit (0<noteText.length)
    };

    ///////////////////////////////////////////////////////////////
    // format for NotesDescriptor
    /*
    {
        screenId:'screenId' ,
        lastScreenId:'lastScreenId' ,
        noteTitle:'noteTitle' ,
        serviceName:< append serviceinfo to title> ,  //optional
        placeholder:'placeholder' ,
        sendButtonText:'sendButtonText' ,
    }
    */

    const type = SessionData.sendNotesUiDescriptor.noteType
    const typeIntent = SessionData.sendNotesUiDescriptor.typeIntent
    const screenId = SessionData.sendNotesUiDescriptor.screenId
    const lastScreenId = SessionData.sendNotesUiDescriptor.lastScreenId
    const noteTitle = SessionData.sendNotesUiDescriptor.noteTitle
    const serviceName = SessionData.sendNotesUiDescriptor.serviceName
    const organization = SessionData.sendNotesUiDescriptor.organization
    const placeholder = SessionData.sendNotesUiDescriptor.placeholder
    const sendButtonText = SessionData.sendNotesUiDescriptor.sendButtonText

    const sendExitHandler = () => {
        //console.log("> SNS.sendExitHandler()")
        SessionData.sendNotesReportObject = {
            noteType:type,
            noteText:noteText,
            service:serviceName,
            organization:organization
        }
        SessionData.sendNotesUiDescriptor = {}
        SessionData.sendingNote = true
        props.setScreen(lastScreenId)
    }

    let noteTitleText = "" + noteTitle + ((undefined===serviceName)?"":serviceName)
    return ( 
        <div>
            <Grid container direction='column' spacing={3}>
                <Grid>
                    <SfyHeader context='SFY' screenId={screenId}  lastScreenId={lastScreenId}  setScreen={props.setScreen}  Settings={false} />
                </Grid >
                <Paper style={{maxHeight: '100vh', overflow: 'auto'}}>
                    <Box ml={3} mr={3} mt={0}>
                        <Grid item xs={12} sm={6}>
                            <Grid item>
                                <Box mt={2} mb={2}> 
                                    <Typography component={'span'} variant="body1" color="primary" >
                                        {typeIntent}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                {noteTitleText}
                            </Grid>
                            <Grid item>
                                <Box mt={2}>
                                    <TextField
                                        multiline={true}
                                        aria-label='problem text' 
                                        fullWidth = {true}
                                        minRows={10}
                                        maxRows={15}
                                        variant='outlined'
                                        placeholder={placeholder}
                                        onChange ={onCodeChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mt={2} mb={2}>
                                    <Button 
                                        onClick={sendExitHandler}
                                        variant="contained" 
                                        color="primary" 
                                        disabled={!canSubmit}
                                    >
                                        {sendButtonText}
                                    </Button>
                                </Box>
                            </Grid> 
                        </Grid>
                    </Box>
                </Paper>
            </Grid >
        </div>
    )
}