const Utils = {}

Utils.traceLoad = false
Utils.traceRejection = false
Utils.traceWaitForLoad = false
Utils.distillOnDevice = false
Utils.debugLocation = false

Utils.weeksDays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
]

Utils.millisecondsPerDay = 1000 * 60 * 60 *24

/*
const traceUtils = false
Utils.convertTagArrayToString = (tagArray,forURL = false) => {
    let tagCount = tagArray.length
    if (0 === tagCount) {
        return ""
    }
    else {
        let ret = ""
        for (let index = 0; index < tagCount; ++index) {
            let convertedTag = tagArray[index]
            if (forURL) {
                convertedTag =  encodeURIComponent(convertedTag)
            }
            Utils.trace(traceUtils, "*** " +tagArray[index]+ " -> " +convertedTag)
            ret += convertedTag + ((index < tagCount-1)?', ':'')
        }
        Utils.trace(traceUtils,"***** " + ret)
        return ret
    }
}

Utils.cleanTimeString = (timeString) => {
    let hasAm = timeString.includes("AM")
    let suffix = hasAm?"a":"p"
    let hours = timeString.substring(0,2).replaceAll('0','')
    let minutes = timeString.substring(3,5)
    let haveMinutes = true
    if ('00'===minutes) {
        haveMinutes = false
        minutes = ''
    }
    return hours + (haveMinutes?(":" + minutes):'') + suffix
}

Utils.daysInSequence = (days) => {
    let priorDayIndex = Utils.weeksDays.indexOf(days[0])
    let count = days.length
    for(let index = 1; index<count;++index) {
        let dayIndex = Utils.weeksDays.indexOf(days[index])
        if (dayIndex!==((priorDayIndex+1)%7)) {
            return [days[0],days[0]]
        }
        priorDayIndex = dayIndex
    }
    return [days[0],days[count-1]]
}

Utils.abbreviateDay = (day) => {
    if ((day[0]==='t') || (day[0]==='s')){
        let shortName = day.substring(0,2)
        shortName = shortName[0].toUpperCase() + shortName[1]
        return shortName
    }
    else {
        return (day[0]).toUpperCase()
    }
}
*/

Utils.addTimeString = () => {
    let time = new Date()
    let day = time.getDay()
    let hour = time.getHours() % 12
    let min = time.getMinutes()
    let sec = time.getSeconds()
    let milli = time.getMilliseconds()
    return " @ " + Utils.weeksDays[day] + "." + hour + ":" + min + ":" + sec + "." + milli
}

Utils.trace = (trigger,message) => {
    if (trigger) {
        console.log(message)
    }
}

Utils.warnDeveloper = (trigger,message) => {
    if (trigger) {
        console.log("WARN DEVELOPER: " + message)
    }
}

// provides prefix used for finding agency specific data on the server
Utils.getUniqueAgencyId = (pd) => { 
    // for now generate a unique but verbose nickname 
    // TODO need to change over to unique nickname set by admin
    // return UpdatingData.agencyIdentifier["Unique Nickname"]
    let ret =   pd["agency"].replaceAll(' ', '') +  
                pd["zip code"] +
                pd["email domain"];
    //console.log("getUniqueAgencyId() -> " + ret)
    return ret
}

Utils.sortArrayByKey = (dataArray, key) => {
    dataArray.sort((a,b) => (a[key] > b[key])?1:-1)
    return  dataArray
}

export default Utils