// SettingsData is intended to hold data at runtime that 
// persists beween sessions until the user logs out .
// All of this data is set on the registration screen

const SettingsData = {}
SettingsData.verified = false           /// set to true once a regisgration has been verified
SettingsData.rawEmailAddress = ''       /// an email address known to the server to be entitled to use SFY 311 for policeDepartment
SettingsData.policeDepartment = {}      /// a PD known to the server to be entitled to use SFY 311
SettingsData.globalTester = false       /// 
SettingsData.categoryTester = false     ///
SettingsData.user = {}                  ///
SettingsData.inDemoMode = false         ///
SettingsData.demoEmailAddress = ""      ///
SettingsData.LawEnforcementRole="empty" ///
SettingsData.sessionKey = ''            // provided by the server when the user has been verified, used to establish identity of web service calls
SettingsData.updateSessionKey = false   // set to true when before loading to make sure the user is still authorized
SettingsData.getFirstSessionKey = false // set to true after registration has been verified
SettingsData.serviceCategories = []     // initially acquired from the server based on the policeDepartment
SettingsData.allServices = {}           // initially acquired from the server 
SettingsData.servicesByCategory = {}    // ClientData.sortServicesByCategory() creates this from allServices
SettingsData.diversionServicesDir= ""   // DSD email gets all diversion requests.
SettingsData.entitlementAdmin = ""      // EA email gets new user information.
SettingsData.localExtraTextHeaderInfo = ""      // EA email gets new user information.

/*
SettingsData.CacheLocally = (key, data) => {
    let stringToCache = JSON.stringify(data)
    console.log("0 CacheLocally key: "+key+", type:"+ (typeof data) + ", data: " + stringToCache)
    if ((typeof data === "string") || (data instanceof String)) {
        console.log("stringToCache: " + stringToCache)
        stringToCache = data
    }
    console.log("1 CacheLocally key: "+key)
    window.localStorage.setItem(key, stringToCache)  
    console.log("2 CacheLocally key: "+key)             
}
//window.localStorage.setItem(key, JSON.stringify(data))  
*/

SettingsData.clearLocal = () => {
    console.log("clearLocal()")
    window.localStorage.clear()
}

SettingsData.clearSession = () => { 
    console.log("clearSession()")   
    SettingsData.verified = false    
    SettingsData.rawEmailAddress = ''
    SettingsData.policeDepartment = {} 
    SettingsData.globalTester = false 
    SettingsData.categoryTester = false  
    SettingsData.user = {}
    SettingsData.inDemoMode = false
    SettingsData.LawEnforcementRole = "empty" 
    SettingsData.sessionKey = '' 
    SettingsData.updateSessionKey = false
    SettingsData.getFirstSessionKey = false 
    SettingsData.serviceCategories = []
    SettingsData.allServices = {}
    SettingsData.servicesByCategory = {} 
    SettingsData.diversionServicesDir = ""
    SettingsData.entitlementAdmin = ""
    SettingsData.localExtraTextHeaderInfo = ""
}

SettingsData.restoreSessionFromLocal = () => {
    let verified = window.localStorage.getItem('verified')  
    console.log("restoreSessionFromLocal--verified " + verified)
    if ((verified !== null)&&(verified !== undefined)) {
        try {
            SettingsData.verified           = window.localStorage.getItem('verified')
            SettingsData.rawEmailAddress    = window.localStorage.getItem('emailAddress')
            console.log(">>>>>SD.rawEmailAddress: " + SettingsData.rawEmailAddress)
            SettingsData.inDemoMode         = ("true"===window.localStorage.getItem('demoUser'))
            console.log(">>>>>SD.inDemoMode: "   + SettingsData.inDemoMode)
            SettingsData.policeDepartment   = JSON.parse(window.localStorage.getItem('policeDepartment'))
            SettingsData.globalTester       = ("true"===window.localStorage.getItem('globalTester')) 
            SettingsData.categoryTester     = ("true"===window.localStorage.getItem('categoryTester')) 
            SettingsData.user               = JSON.parse(window.localStorage.getItem('user'))
            SettingsData.sessionKey         = window.localStorage.getItem('webServiceKey') 
            let categories                  = window.localStorage.getItem('serviceCategories')
            SettingsData.serviceCategories  = ((null===categories) || (0===categories.length)) ? [] : JSON.parse(categories)
            let allServices                 = window.localStorage.getItem('allServices')
            SettingsData.allServices        = ((null===allServices) || (0===allServices.length)) ? {} : JSON.parse(allServices)
            let servicesByCategory          = window.localStorage.getItem('servicesByCategory')
            SettingsData.servicesByCategory = ((null===servicesByCategory) || (0===servicesByCategory.length)) ? {} : JSON.parse(servicesByCategory )
            SettingsData.diversionServicesDir = ""
            SettingsData.entitlementAdmin   = ""
            SettingsData.localExtraTextHeaderInfo = ""
        }
        catch (error) {
            console.log("error on restoreSessionFromLocal "+ error)
            alert(error)
        }
    }
}

// https://mathiasbynens.be/notes/localstorage-pattern
// https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage

SettingsData.getActualEmailAddress = () => {
    let rawEmailAddressLc = SettingsData.rawEmailAddress.toLowerCase()
    let emailCleaned = rawEmailAddressLc

    let newCatsStr = '.update'
    let newCatsPos =  emailCleaned.indexOf(newCatsStr)
    if (-1 !== newCatsPos) {
        let newCatsLength = newCatsStr.length
        let newCatsEnd = newCatsPos + newCatsLength
        emailCleaned = emailCleaned.substring(0,newCatsPos)  + emailCleaned.substring(newCatsEnd)
    }
    //console.log("getActualEmailAddress: 1 "+emailCleaned) 
    let testerStr = '.tester'
    let testerPos =  emailCleaned.indexOf(testerStr)
    if (-1 !== testerPos) {
        let testerLength = testerStr.length
        let testerEnd = testerPos + testerLength
        emailCleaned = emailCleaned.substring(0,testerPos)  + emailCleaned.substring(testerEnd)
    }
    //console.log("getActualEmailAddress: 2 "+emailCleaned)
    return  emailCleaned
}

SettingsData.getTestingString = () => {
    return (SettingsData.globalTester?" (remote)":"") + 
    (SettingsData.categoryTester?" (update)":"")
}

//SettingsData.verified = false    
//SettingsData.rawEmailAddress = ''
//SettingsData.policeDepartment = {} 
//SettingsData.globalTester = false 
//SettingsData.categoryTester = false  
//SettingsData.user = {}
//SettingsData.sessionKey = '' 
//SettingsData.updateSessionKey = false
//SettingsData.getFirstSessionKey = false 
//SettingsData.serviceCategories = []
//SettingsData.allServices = {}
//SettingsData.servicesByCategory = {} 


export default SettingsData

