import React from 'react';
import { ThemeProvider, createTheme, } from '@material-ui/core/styles';

// all screens
import App from "../App";
import FindServices from "./FindServices";
import RegisterScreen from "./RegisterScreen";
import RegisterErrorScreen from "./RegisterErrorScreen";
import VerificationScreen from "./VerificationScreen";
import VerificationErrorScreen from "./VerificationErrorScreen";
import SessionErrorScreen from "./SessionErrorScreen";
import BadgeScreen from "./BadgeScreen";
import SettingsScreen from "./SettingsScreen";
import SendNoteScreen from "./SendNoteScreen";
import SendServices from "./SendServices";
import DiversionOptionsScreen from "./DiversionOptionsScreen";
import DiversionContactScreen from "./DiversionContactScreen";
import DiversionSendScreen from "./DiversionSendScreen";

// global data
import SessionData from "./SessionData";
import ServiceDetailScreen from './ServiceDetailScreen';
import AddContactsScreen from './AddContactsScreen';

const theme = createTheme({
    palette: {
      primary: {
          main: '#12ABF7'
      },
      secondary: {
        main: '#054380' 
      },
      text: {
        main: '#FFFFFF' 
      }
    }
  });

function traceScreenStack()
{
  var ret = ''
  SessionData.screenHistory.forEach((item) => {ret += (item + '; ')} )
  return ret
}

function getLastScreenId () {
  let lastItemIndex = SessionData.screenHistory.length -1
  let lastScreenId = SessionData.screenHistory[lastItemIndex]
  return lastScreenId
}

function updateScreenStack(newScreenId) {
  var lastItemIndex = SessionData.screenHistory.length -1
  var lastScreenId = SessionData.screenHistory[lastItemIndex]
  var priorScreenId = SessionData.screenHistory[lastItemIndex-1] 
  if (priorScreenId===newScreenId) // returning to prior screen so pop the last two
  {
    SessionData.screenHistory.pop()
    SessionData.screenHistory.pop()
  }
  lastItemIndex = SessionData.screenHistory.length -1
  lastScreenId = SessionData.screenHistory[lastItemIndex]
  if (lastScreenId !==newScreenId) {
    SessionData.screenHistory.push(newScreenId)
  }
  return lastScreenId
}

export default function SwitchScreen(props) {
  let debug = false
  let newScreenId = props.screen
  if (debug) {
    console.log("SwitchScreen NEW: " +newScreenId)
  }

  let lastScreenId = getLastScreenId()
  if (('register-error-screen' !== newScreenId) &&
      ('verification-error-screen' !== newScreenId) &&
      ('session-error-screen' !== newScreenId)) {
    lastScreenId = updateScreenStack(newScreenId)
  }
  if (debug) {
    console.log("STACK: "+ traceScreenStack() + ",newScreenId: " +newScreenId+ ",lastScreenId: " +lastScreenId)
  }
  
  switch(newScreenId) 
  {
    case 'reset-app':
    SessionData.resetting = true
    return (
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    )
    case 'register-screen':
    return (
      <ThemeProvider theme={theme}>
        <RegisterScreen  lastScreenId={lastScreenId} setScreen={props.setScreen}/>
      </ThemeProvider>
    )
    case 'register-error-screen':
      return (
        <ThemeProvider theme={theme}>
          <RegisterErrorScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'verification-screen':
      return (
        <ThemeProvider theme={theme}>
          <VerificationScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'verification-error-screen':
      return (
        <ThemeProvider theme={theme}>
          <VerificationErrorScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'session-error-screen':
      return (
        <ThemeProvider theme={theme}>
          <SessionErrorScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'find-services':
      return (
        <ThemeProvider theme={theme}>
          <FindServices lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'badge-screen':
      return (
        <ThemeProvider theme={theme}>
            <BadgeScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )    
    case 'settings-screen':
      return (
        <ThemeProvider theme={theme}>
          <SettingsScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'service-detail-screen':
      return (
        <ThemeProvider theme={theme}>
          <ServiceDetailScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'diversion-options-screen':
      return (
        <ThemeProvider theme={theme}>
          <DiversionOptionsScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'diversion-contact-screen':
      return (
        <ThemeProvider theme={theme}>
          <DiversionContactScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case  'add-contact-screen':
      return (
        <ThemeProvider theme={theme}>
          <AddContactsScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'diversion-send-screen':
      return (
        <ThemeProvider theme={theme}>
          <DiversionSendScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'send-note-screen':
      return (
        <ThemeProvider theme={theme}>
          <SendNoteScreen lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    case 'send-services':
      return (
        <ThemeProvider theme={theme}>
          <SendServices lastScreenId={lastScreenId} setScreen={props.setScreen}/>
        </ThemeProvider>
    )
    default:
      return (
      <div>
          {'Undefined screen |' + newScreenId +'|'}
      </div>
    )
  }
}