import UpdatingData from "../Services/UpdatingData";
import Geolocate from "../Services/Geolocate"
import SessionData from "../components/SessionData"
import SettingsData from "../components/SettingsData"
import Utils from "./Utils"

const UpdateLocation = {}

UpdateLocation.forAllServiceData = () => {
    Utils.trace(Utils.debugLocation, "UL.forAllServiceData > " + Geolocate.haveLast)
    let catCount = 0
    if (Geolocate.haveCurrent) {
        let categories = Object.keys(SettingsData.servicesByCategory)
        Utils.trace(Utils.debugLocation, "UL.forAllServiceData cat count: "+ categories.length)
        categories.forEach (category => {
            catCount++
            Utils.trace(Utils.debugLocation, "updating " + category + " in UL.forAllServiceData.")
            let services = SettingsData.servicesByCategory[category]
            services.forEach(serviceData => {
                Utils.trace(Utils.debugLocation, "     updating " + serviceData.service) 
                UpdateLocation.forService(serviceData)
            })
            //console.log(category + " shows "+showCount + " services of " + services.length)
        })
    }
    Geolocate.screenUpdateCount++
    Geolocate.updatedCategories = catCount
    SessionData.updateFindServicesPage()
    Utils.trace(Utils.debugLocation, "UL.forAllServiceData <")
}

UpdateLocation.forService = (serviceData) => {
    let geoLocation = serviceData["geoLocation"]
    let lat_long = geoLocation.split(',') 
    let distance = getOfficeDistance(serviceData["service"],lat_long[1], lat_long[0])
    serviceData["currentDistance"]  = distance          
}

const getOfficeDistance = (key,longitude,latitude) => {
    let distance = Geolocate.geodesicDistance(key,UpdatingData.currentLongitude,UpdatingData.currentLatitude,longitude,latitude)
    return distance
}

export default UpdateLocation