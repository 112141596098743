import React from "react"
import { Fragment } from 'react'
import { ListItem, ListItemText, Typography, Grid, Box, } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ServiceTypeList from "./ServiceTypeList"
import ClientData from "../Services/ClientData"
import Utils from "../Utilities/Utils"

// global data
import SessionData from './SessionData'
import SettingsData from './SettingsData'

export default function CollapsableServiceTypeList(props) {
    //console.log("CollapsableServiceTypeList")
    const [ignore, triggerChange ]= React.useState(false);
    let categoryIndex = props.categoryIndex
    let serviceCategories = SettingsData.serviceCategories
    //console.log ("CollapsableServiceTypeList: " + JSON.stringify(serviceCategories,null,3))
    var serviceCategoryName = serviceCategories[categoryIndex]

    const updateUi = () => {
      triggerChange(!ignore)
    }
    
    const handleClick = () => {
      ClientData.toggleCategoryDisplay(serviceCategoryName,updateUi)
    }
    
    let categoryServices =  SettingsData.servicesByCategory[serviceCategoryName]
    let selectionCount = SessionData.selectionCount(categoryServices)
    let isOpen = SessionData.itemInArr(serviceCategoryName, SessionData.openCategories)
    Utils.warnDeveloper ((undefined === categoryServices) && isOpen,serviceCategoryName + " is open with undefined CS" ) 
    
    return (
        <Fragment>
            <ListItem key={'MIKEY'+categoryIndex} button onClick={handleClick}>
                <ListItemText 
                  secondary={ 
                    <Grid container component={'span'} direction='row' alignItems='center'>
                      <Typography component={'span'} variant="h6" color="secondary" >
                          {serviceCategoryName}
                      </Typography>
                      <Box component={'span'} ml= {2} >
                        <Typography component={'span'} variant="h6" color="secondary" >
                            {(0===selectionCount)?"":(" ("+ selectionCount + ")")}
                        </Typography>
                      </Box>
                      <Box component={'span'} ml= {2} >
                      </Box>
                      </Grid>
                  }
                />
                {isOpen ? < ExpandLess /> : < ExpandMore />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <ServiceTypeList serviceCategoryName={serviceCategoryName} servicesData={props.servicesData} setScreen={props.setScreen}/>
            </Collapse>
        </Fragment>
    )
  }  