import React from 'react';
import SfyHeader from "./SfyHeader"
import {Typography, List,Fab, Box, Icon } from '@material-ui/core';
import DiversionListItem from './DiversionListItem';
 import Geolocate from "../Services/Geolocate"

// global data
import DiversionData  from './DiversionData';
import UpdatingData  from '../Services/UpdatingData';

export default class DiversionOptionsScreen extends React.Component {
    state = {
        rerender:false
    }

    constructor(props){
        //console.log("DOS.ctor()")
        super()
        this.reRender = this.reRender.bind(this)
        this.showNext = this.showNext.bind(this)
    }

    reRender () {
        let newVal = !this.state.rerender
        this.setState({rerender:newVal})
    }
    
    showNext () {
        const timestamp = new Date(Date.now())
        let optionsDate = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        }
        let optionsTime = {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        }
        const dateFormatted = Intl.DateTimeFormat("en-US",optionsDate).format(timestamp)
        const timeFormatted = Intl.DateTimeFormat("en-US",optionsTime).format(timestamp)
        const dt = dateFormatted + " at approximately " +timeFormatted
        //console.log(">>>> " + dt)
        DiversionData.DiversionTime = dt
        DiversionData.LatAtDiversionChoice = UpdatingData.currentLatitude
        DiversionData.LongAtDiversionChoice = UpdatingData.currentLongitude
        this.props.setScreen('diversion-contact-screen')
    }
    
    render () {
        //console.log("1 DOS.render()")
        if (!Geolocate.haveCurrent) { 
            Geolocate.initialize()
        }
        //console.log("2 DOS.render()")

        let diversionCount = DiversionData.diversionTitles.length
        let selectedCount = DiversionData.selectedDiversions.length 
        //console.log("DOS.render() diversion count: " + diversionCount +
        //            ", selected count: " + selectedCount)
        return ( 
            <div>
                <SfyHeader context='SFY' screenId={'diversion-options-screen'}  lastScreenId={this.props.lastScreenId}  setScreen={this.props.setScreen}  Settings={true} />
                <div>
                    <Box ml= {2} mt={2}>
                        <Typography component={'span'} variant="h5" color="primary" >
                            Select All Applicable Offenses:
                        </Typography>
                    </Box>
                    <List> 
                        {[...Array(diversionCount).keys()].map((index) => {
                            //console.log("index: "+index+", "+DiversionData.diversionTitles[index])
                            return (
                                <DiversionListItem index={index} resetNext={this.reRender} setScreen={this.props.setScreen} />
                            )
                        })}
                    </List>
                    <Box ml={2} mt={0}> 
                        <Fab color='secondary' variant="extended" disabled={0===selectedCount} onClick={this.showNext}>
                            Next
                            <Icon>arrow_forward</Icon> 
                        </Fab>
                    </Box>
                </div>
            </div>
        )
    }
}