import React from 'react';

import { Checkbox, ListItem, Typography, Grid } from '@material-ui/core';

// global data
import DiversionData  from './DiversionData';

export default class DiversionListItem extends React.Component {
    state = {
        rerender:false
    }
    
    constructor(props){
        super()
        this.reRender = this.reRender.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
    }
    
    reRender () {
        let newVal = !this.state.rerender
        this.setState({rerender:newVal})
    }

    handleToggle() {
        let index = this.props.index
        //console.log("> handleToggle(" + index +"} " + JSON.stringify(DiversionData.selectedDiversions))
        if (DiversionData.selectedDiversions.includes(index)) {
            const withoutIndex = DiversionData.selectedDiversions.filter(function (diversionIndex) {
                return diversionIndex !== index
            })
            DiversionData.selectedDiversions = withoutIndex
        }
        else {
            DiversionData.selectedDiversions.push(index)
        }
        this.props.resetNext()
        this.reRender()
        //console.log("< handleToggle(" + index +"} " + JSON.stringify(DiversionData.selectedDiversions))
    }

    render () {
        return (
            <ListItem>
                <Grid container component={'span'} direction='row' alignItems='center'>
                    <Checkbox
                        onClick={this.handleToggle}
                        edge="start"
                        color="primary"
                        checked={DiversionData.selectedDiversions.includes(this.props.index)}
                    />
                    <Typography component={'span'} variant="h6" color="secondary" >
                        {this.props.diversionText}
                    </Typography>
                </Grid>
            </ListItem>
        )
    }
}