import React from 'react';
import SfyHeader from "./SfyHeader"
import SwitchScreen from "./SwitchScreen";
import { Box, Grid } from '@material-ui/core';

// Global Data
import SettingsData from "./SettingsData";
import ServerData from "./ServerData";
import TransmitOnline from './TransmitOnline';

class SessionErrorScreen extends React.Component {
    state = {
        gettingSessionKey: false,
        nextScreen: 'register-screen',
        checkFailed: false,
        mounted: false,
        errorMessage: ""
    }

    constructor(props) {
        super()
    }

    async componentDidMount() {
        //console.log("##### accepted: "+RegistrationData.userAndDepartmentAccepted +",  verified: "+SettingsData.verified + ", getFirstSessionKey: " + SettingsData.getFirstSessionKey)
        // came from the App or from Verification check in VerificationErrorScreen
        if (SettingsData.getFirstSessionKey || SettingsData.updateSessionKey) {
            //console.log("getting SessionKey")
            let sessionUpdated = false
            try {
                this.setState({gettingSessionKey: true })
                let actualEmail = SettingsData.getActualEmailAddress().toLowerCase()
                let url = ServerData.getServerRootUrl() + "getAppSessionKey?email="+ actualEmail+"&role=" +SettingsData.LawEnforcementRole
                console.log("getAppSessionKey URL: " + url)
                url = url.replace(/ /g,'%20')
    
                const response = await fetch(url)
                const data = await response.text()
                this.setState({gettingSessionKey: false })
                if (!this.state.gettingSessionKey) {
                    // update the session key
                    let newSessionKey = data
                    SettingsData.sessionKey = newSessionKey
                    console.log("getAppSessionKey--sessionKey: " + SettingsData.sessionKey)
                    if (""===newSessionKey) {
                        sessionUpdated = false
                        this.setState({errorMessage: "There was a problem verifyng this new session with the server.  Please verify your network connection and re-register."})
                    }
                    else {
                        if (SettingsData.verified) {
                            TransmitOnline.reportEvent("userVerified",SettingsData.rawEmailAddress)
                        }
                        sessionUpdated = true
                        this.setState({nextScreen: "badge-screen"})
                    }
                }
            }
            catch (error) {
                sessionUpdated = false
                this.setState({errorMessage: "There was a problem verifyng this new session with the server.  Please verify your network connection and re-register."})
                let message  = "Session ErrorScreen failed to get a session key from the server: " + error
                console.log("ERROR on getAppSessionKey "+message)
                alert(message)
            }
            if (SettingsData.getFirstSessionKey && !sessionUpdated) {
                // it should always be possible to get a first session key unless
                // some sort of system error occurred,  perhaps a network problem or
                // maybe the user or department became unauthorized after verification, but
                // before asking for the session key 
                this.setState({errorMessage: "There was an unexpected system problem verifyng this new session with the server.  Please verify your network connection and re-register."})
            }
            if (!sessionUpdated) {
                SettingsData.clearSession()
            }
            this.setState({checkFailed: !sessionUpdated})
            SettingsData.updateSessionKey = false
        }
        this.setState({mounted: true})
    }

    render () {
        //console.log("ES.render()" + this.state.nextScreen )
        if (!this.state.mounted)
        {
            return <div></div>
        }
        // if the current check failed, display the ErrorScreen with appropriate info, otherwise switch to the next screen 
        if (this.state.checkFailed) {
            return (
                <Grid container direction='column' spacing={3}>
                    <Grid>
                        <SfyHeader screenId={'session-error-screen'}  lastScreenId={this.state.nextScreen} setScreen={this.props.setScreen}  Settings={false} />
                    </Grid>
                    <Box ml={3} mt={4} mr={3} >
                        <Grid item>
                            {this.state.errorMessage}
                        </Grid>
                    </Box>
                </Grid>
            )
        }
        else {
            //console.log("ERROR SCREEN CHECK OKAY" + this.state.nextScreen )
            return (
                <div>
                    <SwitchScreen screen={this.state.nextScreen} setScreen={this.props.setScreen} />
                </div>
            )
        }
    }
}

export default SessionErrorScreen
