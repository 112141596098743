import React from 'react';
import SfyHeader from "./SfyHeader"
import Format  from '../Utilities/Format';
import { Grid, TextField, Fab, Box, Icon } from '@material-ui/core';

// global data
import DiversionData  from './DiversionData';

export default class AddContactsScreen extends React.Component {
    state = {
        name2:DiversionData.Contact2Name, 
        phone2:DiversionData.Contact2Phone, 
        email2:DiversionData.Contact2Email, 
        note2:DiversionData.Contact2Note , 
        name3:DiversionData.Contact3Name, 
        phone3:DiversionData.Contact3Phone, 
        email3:DiversionData.Contact3Email, 
        note3:DiversionData.Contact3Note,
        canContact:DiversionData.CanContact, 
        haveYouthName:DiversionData.HaveYouthName, 
        youthName:DiversionData.YouthName, 
    }
    constructor(props){
        super()
        this.showNext = this.showNext.bind(this)
        this.ChangeName2 = this.ChangeName2.bind(this)
        this.ChangePhone2 = this.ChangePhone2.bind(this)
        this.ChangeEmail2 = this.ChangeEmail2.bind(this)
        this.ChangeNote2 = this.ChangeNote2.bind(this) 
        this.ChangeName3 = this.ChangeName3.bind(this)
        this.ChangePhone3 = this.ChangePhone3.bind(this)
        this.ChangeEmail3 = this.ChangeEmail3.bind(this)
        this.ChangeNote3 = this.ChangeNote3.bind(this) 
        this.RepeatedString = this.RepeatedString.bind(this)
        //this. = this..bind(this)
    }

    RepeatedString = (name1,name2) => {
        name1 = name1.replaceAll(' ', '')
        name2 = name2.replaceAll(' ', '')
        name1 = name1.toLowerCase()
        name2 = name2.toLowerCase()
        if ((0===name1.length)&&(0===name2.length)) {
            return false
        }
        let ret = (name1===name2)
        //console.log("RepeatedString: " + name1 +", " +name2)
        return ret
    }

    showNext = () => {
        // if the first page can send then this one
        // may skip unfinished fields and say their
        // contacts will be ignored
        let goodName2 = (1 < DiversionData.Contact2Name.length)
        let goodPhone2 = DiversionData.HaveValid2Phone
        let goodEmail2 = DiversionData.HaveValid2Email
        let okay2 = (goodName2 && goodPhone2) || ((0===DiversionData.Contact2Name.length) && (0===DiversionData.Contact2Phone.length) && (0===DiversionData.Contact2Email.length))
        let goodName3 = (1 < DiversionData.Contact3Name.length)
        let goodPhone3 = DiversionData.HaveValid3Phone
        let goodEmail3 = DiversionData.HaveValid3Email
        let okay3 = (goodName3 && goodPhone3) || ((0===DiversionData.Contact3Name.length) && (0===DiversionData.Contact3Phone.length) && (0===DiversionData.Contact3Email.length))
        DiversionData.IgnoreContact2 = this.RepeatedString(DiversionData.Contact1Name, DiversionData.Contact2Name ) && this.RepeatedString(DiversionData.Contact1Phone, DiversionData.Contact2Phone )
        DiversionData.IgnoreContact3 = this.RepeatedString(DiversionData.Contact1Name, DiversionData.Contact3Name ) && this.RepeatedString(DiversionData.Contact1Phone, DiversionData.Contact3Phone )
        if (!DiversionData.IgnoreContact3) {
            DiversionData.IgnoreContact3 = this.RepeatedString(DiversionData.Contact2Name, DiversionData.Contact3Name ) && this.RepeatedString(DiversionData.Contact2Phone, DiversionData.Contact3Phone )
        }
        //console.log("showNext()--Ignore: "+DiversionData.IgnoreContact2 +", "+DiversionData.IgnoreContact3)
        let message = ""
        if (!okay2) {
            message += "Your second contact is incomplete and won't be added to your diversion request. "
            if (!goodName2 || !goodPhone2) {
                message += "Your second contact requires: "
            }
            if (!goodName2) {
                message += "a resonable name"
            }
            if (!goodPhone2) {
                if (!goodName2) {
                    message += "; and "
                }
                message += "a 10 digit phone number"
            }
            if (!goodName2 || !goodPhone2) {
                message += ". "
            }
        }
        if (!goodEmail2) {
            message += "Your second contact's optional email is not valid--it will be ignored. "
        }
        if (!okay3) {
            if (0 < message.length) {
                message += "\n\n"
            }
            message += "Your third contact is incomplete and won't be added to your diversion request. "
            if (!goodName3 || !goodPhone3) {
                message += "Your third contact requires: "
            }
            if (!goodName3) {
                message += "a resonable name"
            }
            if (!goodPhone3) {
                if (!goodName3) {
                    message += "; and "
                }
                message += "a 10 digit phone number"
            }
            if (!goodName3 || !goodPhone3) {
                message += ". "
            }
        }
        if (!goodEmail3) {
            message += "Your third contact's email is not valid--it will be ignored. "
        }
        if (DiversionData.IgnoreContact2 || DiversionData.IgnoreContact3) {
            message += "Some of your contacts are the same. Duplicates will be ignored. "
        }
        if (!okay2 || !okay3 || DiversionData.IgnoreContact2 || DiversionData.IgnoreContact3) {
            message += "Cancel to fix things before proceeding. "
        }
        let okay = true
        if (20 < message.length) {
            okay = window.confirm(message)
        }
        if (okay) {
            this.props.setScreen('diversion-send-screen')
        }
    }

    ChangeName2 = (event) => {
        let val = event.target.value
        DiversionData.Contact2Name = val
        this.setState({name2:val})
        Format.setYouthContactMap()
        //console.log("CN2 " + DiversionData.Contact2Name + ", n2: " + this.state.name2 + ", val: " +val)
    }
    ChangePhone2 = (event) => {
        let val = event.target.value
        DiversionData.Contact2Phone = val
        this.setState({phone2:val})
        DiversionData.HaveValid2Phone = Format.validPhoneNumber(DiversionData.Contact2Phone)
        //console.log("CP2 valid: " + DiversionData.HaveValid2Phone + ", text: "+ DiversionData.Contact2Phone + ", p2: " + this.state.phone2 + ", value: " +val)
    }
    ChangeEmail2 = (event) => {
        let val = event.target.value
        DiversionData.Contact2Email = val
        this.setState({email2:val})
        DiversionData.HaveValid2Email = (""===DiversionData.Contact2Email) || Format.validEmailAddress(DiversionData.Contact2Email)
        //console.log("CE2 " + DiversionData.Contact2Email + ", e2: " + this.state.email2 + ", val: " +val)
        //console.log("changeEmail2--valid: " + DiversionData.HaveValid2Email)
    }
    ChangeNote2 = (event) => {
        let val = event.target.value
        DiversionData.Contact2Note = val
        this.setState({note2:val})
        //console.log("CN.2 " + DiversionData.Contact2Note + ", n.2: " + this.state.note2 + ", val: " +val)
    }
    ChangeName3 = (event) => {
        let val = event.target.value
        DiversionData.Contact3Name = val
        this.setState({name3:val})
        Format.setYouthContactMap()
        //console.log("CN3 " + DiversionData.Contact3Name + ", n3: " + this.state.name3 + ", val: " +val)
    }
    ChangePhone3 = (event) => {
        let val = event.target.value
        DiversionData.Contact3Phone = val
        this.setState({phone3:val})
        DiversionData.HaveValid3Phone = Format.validPhoneNumber(DiversionData.Contact3Phone)
        //console.log("CP3 valid: " + DiversionData.HaveValid3Phone + ", text: "+ DiversionData.Contact3Phone + ", p3: " + this.state.phone3 + ", value: " +val)
    }
    ChangeEmail3 = (event) => {
        let val = event.target.value
        DiversionData.Contact3Email = val
        this.setState({email3:val})
        DiversionData.HaveValid3Email= (""===DiversionData.Contact3Email) || Format.validEmailAddress(DiversionData.Contact3Email)
        //console.log("CE3 " + DiversionData.Contact3Email + ", e3: " + this.state.email3 + ", val: " +val)
        //console.log("changeEmail3--valid: " + DiversionData.HaveValid3Email)
    }
    ChangeNote3 = (event) => {
        let val = event.target.value
        DiversionData.Contact3Note = val
        this.setState({note3:val})
        //console.log("CN.3 " + DiversionData.Contact3Note + ", n.3: " + this.state.note3 + ", val: " +val)
    }

    render () {
        //console.log("ACS render--youthName: " + this.state.youthName + ", name2: " +this.state.name2+ ", C2N: " +DiversionData.Contact2Name)
        return ( 
            <div>
                <SfyHeader context='SFY' screenId={'add-contact-screen'}  lastScreenId={this.props.lastScreenId}  setScreen={this.props.setScreen}  Settings={true} />
                <div>
                    <form>
                        <Box ml={3} mr={3}>
                            <Grid container direction='column' spacing={1}>
                                <Box mb={-2}> 
                                    <p style={{color:'#367BBD'}}>Optional Second Contact:</p>
                                </Box> 
                                <Box mr={0}> 
                                    <TextField
                                        id="contact_name_2"
                                        placeholder={"Required Full Name"}
                                        defaultValue={this.state.name2}
                                        fullWidth={true}
                                        color='primary'
                                        margin='dense'
                                        size="small" 
                                        variant='outlined'
                                        onChange ={this.ChangeName2}                      
                                    />
                                    <TextField
                                        id="contact_phone_2"
                                        placeholder={"Required Phone"}
                                        defaultValue={this.state.phone2}
                                        fullWidth={true}
                                        color='primary' 
                                        margin='dense'
                                        size="small"
                                        variant='outlined'
                                        onChange ={this.ChangePhone2}
                                    />
                                    <TextField
                                        id="contact_email_2"
                                        placeholder={"Optional Email"}
                                        defaultValue={this.state.email2}
                                        fullWidth={true}
                                        color='primary'  
                                        margin='dense'
                                        size="small"
                                        variant='outlined'
                                        onChange ={this.ChangeEmail2}            
                                    />
                                    <TextField
                                        id="contact_note_2"
                                        placeholder={"Optional Note"}
                                        defaultValue={this.state.note2}
                                        fullWidth={true}
                                        color='primary'
                                        multiline
                                        minRows={2} 
                                        margin='dense'
                                        size="small" 
                                        variant='outlined'
                                        onChange ={this.ChangeNote2}
                                    />
                                </Box>
                                <Box mb={-2}> 
                                    <p style={{color:'#367BBD'}}>Optional Third Contact:</p>
                                </Box> 
                                <Box mr={0}> 
                                    <TextField
                                        id="contact_name_3"
                                        placeholder={"Required Full Name"}
                                        defaultValue={this.state.name3}
                                        fullWidth={true}
                                        color='primary'
                                        margin='dense'
                                        size="small" 
                                        variant='outlined'
                                        onChange ={this.ChangeName3}                      
                                    />
                                    <TextField
                                        id="contact_phone_3"
                                        placeholder={"Required Phone"}
                                        defaultValue={this.state.phone3}
                                        fullWidth={true}
                                        color='primary' 
                                        margin='dense'
                                        size="small"
                                        variant='outlined'
                                        onChange ={this.ChangePhone3}                      
                                    />
                                    <TextField
                                        id="contact_email_3"
                                        placeholder={"Optional Email"}
                                        defaultValue={this.state.email3}
                                        fullWidth={true}
                                        color='primary'  
                                        margin='dense'
                                        size="small"
                                        variant='outlined'
                                        onChange ={this.ChangeEmail3}            
                                    />
                                    <TextField
                                        id="contact_note_3"
                                        placeholder={"Optional Note"}
                                        defaultValue={this.state.note3}
                                        fullWidth={true}
                                        color='primary'
                                        multiline
                                        minRows={2} 
                                        margin='dense'
                                        size="small" 
                                        variant='outlined'
                                        onChange ={this.ChangeNote3} 
                                    />
                                </Box>  
                            </Grid>
                        </Box>
                    </form>
                    <Box ml={2} mt={2}>
                        <Fab color='secondary' variant="extended" disabled={!this.state.canContact} onClick={this.showNext}>
                            Review & Send
                            <Icon>arrow_forward</Icon> 
                        </Fab>
                    </Box>
                </div>
            </div>
        )
    }
}
