import React from 'react';
import SfyHeader from "./SfyHeader"
import { ListItem, ListItemIcon, Checkbox, Typography, TextField, ListItemText,  Box, Grid, Fab, Icon, Link, /*Paper,*/ } from '@material-ui/core';
//import AuntBerthaLogo from './images/AuntBerthaLogo.png'

// global data
import SessionData  from './SessionData';
import ServerData   from './ServerData';
import SettingsData from './SettingsData';
import TransmitOnline from './TransmitOnline';
import Geolocate from "../Services/Geolocate";



// currently for google maps but could add a switch in settngs to provide apple maps as an alternative
// https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
const openAddressInMaps = () => {
    var serviceData = SessionData.serviceData 
    var organization    = serviceData.organization
    var address         = serviceData.address
    let addressUrl = (organization + ' ' + address).replace(/ /g,'+')
    if ((undefined!==address) && (0<address.length))
    {
        let url = 'https://www.google.com/maps/search/?api=1&query=' +addressUrl
        //console.log('URL: '+ url) 
        window.open(url)
    }
}

const getAddressText = (address) => {
    let trimmedAddress = trimString(address,37)
    let preface = (Geolocate.haveLast?"":"*")
    return (((undefined!==trimmedAddress) && (0===trimmedAddress.length))
            ?   'Unpublished address'
            :   (preface + trimmedAddress))
}

//const onAuntBertha = () => {
//    window.open('https://www.auntbertha.com')
//} 

const getWebsiteLink = (data) => {
    var website         = data.website
    if ((undefined!==website) && (0!==website.length))
        return (<Link color='secondary' onClick={()=>{window.open(website)}}> Website </Link> ) 
    else 
        return (<span></span>)
}

const getPhoneLink = (data) => {
    var phone = data.phone
    if ((undefined!==phone) && (0!==phone.length))
        return ( <Link color='secondary' onClick={()=>{window.open('tel:'+phone)}}> Phone </Link> )
    else 
        return (<span></span>)
}

const optionalText = (data, defaultText, preface) => {
    if ((undefined!==data) && (0<data.length)) {
        return (preface + data + ". ")
    }
    else {
        return defaultText
    }
}

const getAboutText = (data) => {
    var costText = optionalText(data.cost,'Cost is not published. ','Cost: ' )
    var openText = optionalText(data.whenOpen,"Hours not published. ","" )
    var gs = optionalText(data.genderSpecific,"","")
    var languages = optionalText(data.languages,"","")
    return costText +  openText +  gs  + data.about + " " + languages
}

const getEligibilityText = (data) => {
    var serving =  optionalText(data.serving,"","")
    var ageRestriction = optionalText(data.ageRestriction,"","")
    var eligibility =  optionalText(data.eligibility,"","")
    var coverageArea =  optionalText(data.coverageArea,"","")
    let ret = serving + ageRestriction + eligibility + coverageArea
    //console.log(ret)
    return ret
}

const isChecked = () => {
    return (-1 !==  SessionData.selectedServices.indexOf(SessionData.serviceData.serviceKey)) 
}

const trimString = (str, length) => {
    if ((undefined!==str) && (length<str.length)) {
        str = str.substring(0,length) + '...'
    }
    return str
}

export default class ServiceDetailScreen extends React.Component {
    state = {
        selected:false
    }

    constructor(props){
        super()
        let data = SessionData.serviceData
        this.serviceName    = data.service
        this.CategoryFrom   = data.categoryFrom
        this.service        = trimString(data.service,32)
        this.organization   = trimString(data.organization,30)
        this.addressText    = getAddressText(data.address)
        this.aboutText      = getAboutText(data)
        this.mainServices   = data.mainServices
        //console.log('|' + data.serving + ' | ' + data.eligibility + ' | ' + data.coverageArea + ' |')
        this.eligibilityText= getEligibilityText(data)
        this.websiteLink    = getWebsiteLink(data)
        this.phoneLink      = getPhoneLink(data)

        this.handleToggle   = this.handleToggle.bind(this);
        this.onNote         = this.onNote.bind(this);
        this.onSuggest      = this.onSuggest.bind(this);
        this.state.selected = isChecked()
    }
    
    handleToggle() {
        var serviceKey = SessionData.serviceData.serviceKey
        var serviceIndex = SessionData.selectedServices.indexOf(serviceKey)
        //console.log('index: '+serviceIndex+', key '+serviceKey)
        if (-1 ===  serviceIndex) {
          SessionData.selectedServices.push(serviceKey)
        }
        else {
          SessionData.selectedServices.splice(serviceIndex,1)
        }
        //console.log("selectedServices" + JSON.stringify(SessionData.selectedServices,null,3))
        this.setState({selected: isChecked()})
        //SessionData.updateFindServicesPage()
    }

    onNote() {
        //console.log("onNote")
        SessionData.sendNotesReportObject = {}
        SessionData.sendNotesUiDescriptor = {
            noteType:'mnemonic',
            typeIntent: 'Submit a note when you have information about the service you want to share with your collegues.',
            screenId:'add-note-screen' ,
            lastScreenId:'service-detail-screen' ,
            noteTitle: 'Submit a Note about ',
            serviceName: SessionData.serviceData.service,
            organization: SessionData.serviceData.organization,
            placeholder:'Share your note here.' ,
            sendButtonText:'Store Note' ,
        }
        this.props.setScreen('send-note-screen')
    }

    onSuggest(){
        //console.log(("onSuggest")
        SessionData.sendNotesReportObject = {}
        SessionData.sendNotesUiDescriptor = {
            noteType:'suggestion',
            typeIntent: 'Make a suggestion when the service details are not accurate or incomplete.',
            screenId:'send-suggestion-screen' ,
            lastScreenId:'service-detail-screen' ,
            noteTitle: 'Make a suggestion about ',
            serviceName: SessionData.serviceData.service,
            organization: SessionData.serviceData.organization,
            placeholder:'Describe your suggestion here.' ,
            sendButtonText:'Suggest' ,
        }
        this.props.setScreen('send-note-screen')
    }

    async componentDidMount() {
        let eventData = this.CategoryFrom  + "," + this.serviceName
        TransmitOnline.reportEvent("servicePeek",eventData)
        //console.log("SDS.componentDidMount(): "  + ((SessionData.sendingNote)?" ":" not") + " sending " + JSON.stringify(SessionData.sendNotesReportObject))
        if (SessionData.sendingNote) {
            var url = (ServerData.getServerRootUrl()   + 
                "postNote?department=" + SettingsData.policeDepartment['agency'] + 
                "&email=" + SettingsData.getActualEmailAddress() +
                "&noteType=" + SessionData.sendNotesReportObject.noteType +
                "&noteText=" + SessionData.sendNotesReportObject.noteText +
                "&serviceName= " + SessionData.sendNotesReportObject.service +
                "&organization= " + SessionData.sendNotesReportObject.organization)
            url = url.replace(/ /g,'%20')
            console.log(">>>>> componentDidMount: " + url)           
            const requestOptions = {
                method: 'POST',
                mode: 'no-cors',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(url, requestOptions)
            const data = await response.text()
            console.log("status " + response.status + ", " + data)
            SessionData.sendingNote = false
        }
    }

    render () {
        //console.log(">>>>> ServiceDetailScreen.render()")
        return ( 
            <div>
                <SfyHeader context='SFY' screenId={'service-detail-screen'}  lastScreenId={this.props.lastScreenId}  setScreen={this.props.setScreen}  Settings={true} />
                <div>
                    <ListItem alignItems="flex-start" key={SessionData.serviceData.serviceKey} role={undefined} dense  > 
                        <ListItemIcon>
                            <Checkbox
                                onClick={this.handleToggle}
                                edge="start"
                                color="primary"
                                tabIndex={SessionData.serviceData.serviceKey}
                                checked={this.state.selected}
                            />
                        </ListItemIcon>
                        <ListItemText
                            secondary= {
                                <React.Fragment>
                                    <Grid component={'span'} container direction='column'>
                                        <Typography component={'span'} variant="body1" color="#054380" >
                                            {this.service}
                                        </Typography>
                                        <Typography component={'span'} variant="body2" color="#054380" >
                                            {'by ' + this.organization}
                                        </Typography>
                                        <Typography component={'span'} variant="body2" color="#054380">
                                            <Link color="secondary" onClick={openAddressInMaps}> {this.addressText} </Link> 
                                        </Typography>
                                        <Typography component={'span'} variant="body2" color="#054380">
                                            {this.websiteLink}
                                            {this.phoneLink}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    
                    <Box ml={3} mr={3} mt={-1}> 
                        <Grid container direction='column' >
                            <Box mt={-1}> 
                                <Box mb={-2}> 
                                    <p style={{color:'#12ABF7'}}>ABOUT</p>
                                </Box>
                                <TextField
                                    multiline={true}
                                    id="generted-message"
                                    color='#054380'  
                                    fullWidth = {true}
                                    minRows={1}
                                    maxRows={4}
                                    variant='outlined'   
                                    defaultValue= {this.aboutText }                               
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />  
                            </Box> 

                            <Box mb={-2}> 
                                <p style={{color:'#12ABF7'}}>MAIN SERVICES</p>
                            </Box>
                            <TextField
                                multiline={true}
                                id="generted-message"
                                color='#054380'  
                                fullWidth = {true}
                                minRows={1}
                                maxRows={3}
                                variant='outlined'   
                                defaultValue= {this.mainServices}                                 
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                            <Box mb={-2}> 
                                <p style={{color:'#12ABF7'}}>ELIGIBILITY</p>
                            </Box>
                            <TextField
                                multiline={true}
                                id="generted-message"
                                color='#054380'  
                                fullWidth = {true}
                                minRows={1}
                                maxRows={4}
                                variant='outlined'   
                                defaultValue= { this.eligibilityText }                               
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <Box ml={0} mr={0} mt={1} mb={0}>
                                <Fab color='secondary' variant="extended" onClick={this.onNote}>
                                    Submit a note
                                    <Icon>subject</Icon> 
                                </Fab>
                            </Box>
                            <Box ml={0} mr={0} mt={1} mb={0}> 
                                <Fab color='secondary' variant="extended" onClick={this.onSuggest}>
                                    Make a suggestion 
                                    <Icon>edit</Icon> 
                                </Fab>
                            </Box> 
                        </Grid>
                    </Box>
                </div>
            </div>     
        )
    }
}
