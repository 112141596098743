import UpdatingData from "./UpdatingData"
import UpdateLocation from "../Utilities/UpdateLocation"
import Utils from "../Utilities/Utils"
import SettingsData from "../components/SettingsData";

const Geolocate = {}

Geolocate.haveLast = false
Geolocate.haveCurrent = false
Geolocate.haveNaN = false

let updateLongitude = 0.0
let updateLatitude = 0.0

let initialLongitude = 0.0
let initialLatitude = 0.0
let neverSet = true

Geolocate.locationUpdateDelta = 0.0
Geolocate.screenUpdateCount = 0
Geolocate.updatedCategories = 0
Geolocate.updatedService = ""

function success (positon) {
  var location = positon.coords;
  UpdatingData.currentLongitude = location.longitude
  UpdatingData.currentLatitude = location.latitude
  // establish the location of the initial geolocate
  if (neverSet) {
    neverSet = false
    initialLongitude = UpdatingData.currentLongitude
    initialLatitude = UpdatingData.currentLatitude
    console.log("Geolocate initial long lat: [" +initialLongitude + "," +initialLatitude+ "]")
    Geolocate.updateTesterOffset()
  }
  Geolocate.haveLast = true
  Geolocate.haveCurrent = true

  // track delta
  let deltaMiles = Geolocate.geodesicDistance("success",UpdatingData.currentLongitude,UpdatingData.currentLatitude,updateLongitude,updateLatitude)
  let deltaFeet =Math.round(deltaMiles * 5280)
  Geolocate.haveNaN = isNaN(deltaFeet)
  let okDelta = (deltaFeet !== 0) && !Geolocate.haveNaN
  if (okDelta) {
    //console.log('Geolocate.watchPosition SUCCESS delta = ' + deltaFeet + "', " + Utils.addTimeString() )
  }

  // current locations distance from the initial location
  Geolocate.locationUpdateDelta = Math.round(Geolocate.geodesicDistance("initial",UpdatingData.currentLongitude,UpdatingData.currentLatitude,initialLongitude,initialLatitude) * 5280)
  
  // when the device has moved 20 feet from the location
  // of the last update, trigger an update
  if (okDelta && (20 < deltaFeet)) {
    UpdateLocation.forAllServiceData() // will update findServices page when complete
    updateLongitude = UpdatingData.currentLongitude
    updateLatitude = UpdatingData.currentLatitude
  }
}

function error(err) {
  // 1 is no permission
  // 2 is data unavailable
  // 3 is timeout
  Geolocate.haveCurrent = false
  console.log('Geolocate.watchPosition ERROR(' + err.code + ') |' + err.message + "| " + Utils.addTimeString() )
}

var options = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 10000
};

Geolocate.initialize = () => {
  console.log('Geolocate.initialize watchPosition')
  navigator.geolocation.watchPosition(success, error, options);
}

Geolocate.geodesicDistance = (key,lon1,lat1,lon2,lat2) => {
  const R = 6371e3; // metres
  const metersToMiles =  0.000621371
  const degreesToRadians = Math.PI/180
  /*
  if ((0!==testerOffsetLongitude)||(0!==testerOffsetLatitude)) {
    lon1 += testerOffsetLongitude
    lat1 += testerOffsetLatitude
  }
  */
  const LA1 = lat1 * degreesToRadians // φ, λ in radians
  const LA2 = lat2 * degreesToRadians
  const DLO = (lon2-lon1) * degreesToRadians
  let ret = Math.acos( Math.sin(LA1)*Math.sin(LA2) + Math.cos(LA1)*Math.cos(LA2) * Math.cos(DLO) ) * R * metersToMiles
  Utils.trace (Utils.debugLocation,key + "["+lon1+","+lat1+"] to ["+lon2+","+lat2+"] @ " + ret)
  return ret
}

var testerOffsetLongitude = 0;
var testerOffsetLatitude = 0;
Geolocate.updateTesterOffset = () => {
  let rawEmailAddressLc = SettingsData.rawEmailAddress.toLowerCase()
  let testerStr = '.tester'
  let testerPos =  rawEmailAddressLc.indexOf(testerStr)
  //console.log("updateTesterOffset: " + rawEmailAddressLc + ", " +testerPos)
  if (-1 !== testerPos) {
    // agency* should come with the agency data
    let agencyLongitude = -86.15361
    let agencyLatitude = 39.770120
    testerOffsetLongitude = agencyLongitude - initialLongitude
    testerOffsetLatitude = agencyLatitude - initialLatitude
    // NOTE: from cambridge to tippecanoue county approximately
    // it should be from the testers geolocation to the agencies geolocation   
    console.log("testerOffset: [" + testerOffsetLongitude +","+testerOffsetLatitude+"]")
  }
}

export default Geolocate;