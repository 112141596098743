//global data
import SettingsData from "./SettingsData";
import ServerData from "./ServerData";

const PoliceDepartmentData = {}

//WS: getRegisteredDepartments() -> 
PoliceDepartmentData.registeredDepartments = {}

 PoliceDepartmentData.validPoliceDepartment = (department) => {
    if (undefined===department)
        return false;
    if (0===Object.keys(department).length)
        return false;
    // make sure the department Object has an 'email domain' property
    let domain = department['email domain']
    if (undefined===domain)
        return false;
    if (""===domain)
        return false;
    // make sure the department is in registeredDepartments
    let departmentLookedUp = PoliceDepartmentData.registeredDepartments[domain]
    if (undefined===departmentLookedUp)
        return false;
    // and that it has the correct domain
    return (departmentLookedUp['email domain'] === domain)
}

PoliceDepartmentData.getBadgeUrl = () => {
    let url = (ServerData.getServerRootUrl()  +"images/" + SettingsData.policeDepartment['email domain']).replace(/ /g,'%20') + "Badge.png" 
    //console.log("URL: " + url)
    return url
}

export default PoliceDepartmentData;