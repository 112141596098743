import SettingsData from "../components/SettingsData";
import DiversionData from "../components/DiversionData";
import SessionData from "../components/SessionData";
import ServerData from "../components/ServerData";
import TransmitOnline from "../components/TransmitOnline";
import Geolocate from "./Geolocate"
import Utils from "../Utilities/Utils";

var request = require('request');

const ClientData = {}
ClientData.TransmissionIntervalActive = false
ClientData.UpdateTransmissionInterval = null
ClientData.CategoryTrace = ""
ClientData.ServiceTrace = ""
ClientData.LogOn = false
ClientData.log = (file,line,message) => {
    if (ClientData.LogOn) {
        console.log("["+file+","+line+"] " + message)
    }
}

ClientData.initialize = () => {
    loadCategories() 
}

const loadCategories = () => {
    const url =  (ServerData.getServerRootUrl()  + 
        "listMobileData?uniqueNickname=" + Utils.getUniqueAgencyId(SettingsData.policeDepartment) +
        "&categories=true" +
        "&preview=" + SettingsData.categoryTester.toString()
    ).replace(/ /g,'%20')

    ClientData.CategoryTrace += ("loadCategories--url: " + url)
    var options = {
        url:  url,
        method: 'GET'
    }
  
    request(options,(error, response, body) => {
        loadCategoriesCallback(error, response, body)
    })
}

const loadCategoriesCallback = (error, response, body) => {
    ClientData.CategoryTrace += "\n>loadCategoriesCallback"
    let serverErrorMessage = ""
    if (!error) {
        ClientData.CategoryTrace += ("\nresponse.status: " + response.statusCode)
        if (response.statusCode === 200) {
            let categoriesArray = JSON.parse(body)
            let categoryCount = categoriesArray.length
            let categories = new Array(categoryCount)
            categoriesArray.forEach(item => {
                let category = item["category"]
                let indexString = item["index"]
                let intIndex = parseInt(indexString) - 1
                ClientData.CategoryTrace += ("\nat "+intIndex+" is "+category+"")
                if (!isNaN(intIndex)) {
                    categories[intIndex] = category
                }
                else {
                    ClientData.CategoryTrace += ("\nNon numerical ID ("+indexString+") found for a category.")
                }
            });
            SettingsData.serviceCategories = categories
            ClientData.CategoryTrace += ("\nloadCategories succeeded with categories: " + JSON.stringify(categories,null,3))
        }
        else if (response.statusCode === 412) {
            serverErrorMessage = "SYSTEM ERROR ("+body+")"
        }
        else {
            serverErrorMessage = "UNEXPECTED RESPONSE STATUS (" + response.statusCode + "): " + body
        }
    }
    else {
        let catCount = SettingsData.serviceCategories.length
        if (0===catCount) {
            serverErrorMessage = "UNEXPECTED SYSTEM ERROR: " + error
            ClientData.CategoryTrace += ("\n"+ serverErrorMessage)
        }
    }
    if (serverErrorMessage !== "") {
        ClientData.CategoryTrace += ("\n"+ serverErrorMessage)
    }
    loadServiceData()
    ClientData.CategoryTrace += ("\n<loadCategoriesCallback")
}

const loadServiceData = () => {
    const url =  (ServerData.getServerRootUrl()  + 
        "listMobileData?uniqueNickname=" + Utils.getUniqueAgencyId(SettingsData.policeDepartment) +
        "&categories=false" +
        "&preview=" + SettingsData.categoryTester.toString()
    ).replace(/ /g,'%20')
    ClientData.ServiceTrace += ("\nloadServiceData--url: " + url)
    var options = {
        url:  url,
        method: 'GET'
    }
    request(options,(error, response, body) => {
        loadServiceDataCallback(error, response, body)
    })
}

const loadServiceDataCallback = (error, response, body) => {
    ClientData.ServiceTrace +="\n>loadServiceDataCallback"
    let serviceDataErrorMessage = ""
    if (!error) {
        ClientData.ServiceTrace += ("\nresponse.status: " + response.statusCode)
        if (response.statusCode === 200) {
            let dataForClient = JSON.parse(body)
            //console.log ("dataForClient: " + JSON.stringify(dataForClient,null,3))
            SettingsData.allServices = dataForClient
            let serviceCount = dataForClient.length
            ClientData.ServiceTrace += ("\nserviceCount: "+ serviceCount)
            if(0===serviceCount) {
                serviceDataErrorMessage = "A Load Error Occurred: Tap RELOAD on the Find Services screen."
            }
            else {
                serviceDataErrorMessage = sortServicesByCategory(dataForClient)
            }
        }
        else if (response.statusCode === 412) {
            serviceDataErrorMessage = "SYSTEM ERROR ("+body+")"
        }
        else {
            serviceDataErrorMessage = "UNEXPECTED RESPONSE STATUS (" + response.statusCode + "): " + body
        }
    }
    else {
        serviceDataErrorMessage =  "UNEXPECTED SYSTEM ERROR: " + error
    }
    if (serviceDataErrorMessage !== "") {
        ClientData.ServiceTrace += ("\nloadServiceDataCallback serverErrorMessage: " + serviceDataErrorMessage)
    }
    TransmitOnline.reportEvent ("loadData",SettingsData.rawEmailAddress)
    loadDsd()
    ClientData.ServiceTrace += "\n<loadServiceDataCallback"
}

// Diversion Services Director
const loadDsd = () => {
    if ((undefined !== SettingsData.diversionServicesDir) &&
        (null !== SettingsData.diversionServicesDir) &&
        (0<SettingsData.diversionServicesDir.length)) {
            ClientData.log("CD",153,">>>>> loadDsd-- already ran: " + SettingsData.diversionServicesDir)
        return
    }
    const url =  (ServerData.getServerRootUrl()  + 
        "listDiversionServicesDirector?agency=" + SettingsData.policeDepartment['email domain'] +
        "&userSessionKey=" + SettingsData.sessionKey
    ).replace(/ /g,'%20')
    //console.log(">>>>> loadDsd--url: " + url)
    var options = {
        url:  url,
        method: 'GET'
    }
    request(options,(error, response, body) => {
        loadDsdCallback(error, response, body)
    })
}

const loadDsdCallback = (error, response, body) => {
    SettingsData.diversionServicesDir = null
    let serverErrorMessage = ""
    if (!error) {
        ClientData.log("CD",176,"loadDsd--response.status: " + response.statusCode + ", body: "+body);
        if (response.statusCode === 200) {
            let data = JSON.parse(body)
            //console.log ("body: " + JSON.stringify(data) + ", " +data[0]+ "," +data[1]+ "," +data[2])
            SettingsData.diversionServicesDir = data[0]
            DiversionData.YouthMinAge = Number(data[1])
            DiversionData.YouthMaxAge = Number(data[2])
            //console.log ("loadDsd succeeded with: " + SettingsData.diversionServicesDir + "--" +DiversionData.YouthMinAge+ " < " + DiversionData.YouthMaxAge)
        }
        else if (response.statusCode === 412) {
            serverErrorMessage = "loadDsd SYSTEM ERROR ("+body+")"
        }
        else {
            serverErrorMessage = "loadDsd UNEXPECTED RESPONSE STATUS (" + response.statusCode + "): " + body
        }
    }
    else {
        serverErrorMessage = "loadDsd UNEXPECTED SYSTEM ERROR: " + error
        ClientData.log("CD",194, "loadDsd UNEXPECTED SYSTEM ERROR: " + error)    
    }
    if (serverErrorMessage !== "") {
        ClientData.log("CD",197,"loadDsd serverErrorMessage: " + serverErrorMessage)
        alert(serverErrorMessage)
    }
    loadEa()
}

// Entitlement Administrator
const loadEa = () => {
    if ((undefined !== SettingsData.entitlementAdmin) &&
        (null !== SettingsData.entitlementAdmin) &&
        (0<SettingsData.entitlementAdmin.length)) {
        ClientData.log("CD",208,">>>>> loadEa-- already ran: " + SettingsData.entitlementAdmin)
        return
    }
    const url =  (ServerData.getServerRootUrl()  + 
        "listEntitlementAdmin?agency=" + SettingsData.policeDepartment['email domain'] +
        "&userSessionKey=" + SettingsData.sessionKey
    ).replace(/ /g,'%20')
    //console.log("*****loadEa--url: " + url)
    var options = {
        url:  url,
        method: 'GET'
    }
    request(options,(error, response, body) => {
        loadEaCallback(error, response, body)
    })
}

const loadEaCallback = (error, response, body) => {
    let serverErrorMessage = ""
    SettingsData.entitlementAdmin = null
    ClientData.log("CD",230,"loadEa--response.status: " + response.statusCode + ", body: "+body);
    if (!error) {
        //console.log("*****loadEa--response.status: " + response.statusCode);
        if (response.statusCode === 200) {
            let eaData = JSON.parse(body)
            //console.log ("***** loadEa--eaData: " + JSON.stringify(eaData,null,3))
            SettingsData.entitlementAdmin = eaData[0]
            SettingsData.localExtraTextHeaderInfo  = eaData[1]
            //console.log ("***** loadEa--email: " + SettingsData.entitlementAdmin)
            //console.log ("***** loadEa--localExtraTextHeader: " + SettingsData.localExtraTextHeaderInfo)
        }
        else if (response.statusCode === 412) {
            serverErrorMessage = "loadEa SYSTEM ERROR ("+body+")"
        }
        else {
            serverErrorMessage = "loadEa UNEXPECTED RESPONSE STATUS (" + response.statusCode + "): " + body
        }
    }
    else {
        serverErrorMessage = "loadEa UNEXPECTED SYSTEM ERROR: " + error
        ClientData.log("CD",250,"loadEa UNEXPECTED SYSTEM ERROR: " + error)
    }
    if (serverErrorMessage !== "") {
        ClientData.log("CD",253,"*****loadEa serverErrorMessage: " + serverErrorMessage)
        alert(serverErrorMessage)
    }
    ClientData.ensureTransmission()
}

ClientData.ensureTransmission = () => {
    ClientData.log("CD",260,"> ClientData.ensureTransmission:  " +ClientData.UpdateTransmissionInterval+", "+ClientData.TransmissionIntervalActive)
    clearInterval(ClientData.UpdateTransmissionInterval)
    ClientData.UpdateTransmissionInterval = null
    ClientData.TransmissionIntervalActive = false
    ClientData.UpdateTransmissionInterval = setInterval(TransmitOnline.postCachedTransmissions,1000) 
    ClientData.TransmissionIntervalActive = true
    ClientData.log("CD",266,"CategoryTrace: "+ClientData.CategoryTrace)
    ClientData.log("CD",267,"ServiceTrace: "+ClientData.ServiceTrace)
    ClientData.log("CD",268,"<  ClientData.ensureTransmission:  " +ClientData.UpdateTransmissionInterval+", "+ClientData.TransmissionIntervalActive)
}
const sortServicesByCategory = (services) => {
    //console.log("categories " + JSON.stringify(SettingsData.serviceCategories,null,3))
    let serviceSortErrorMessage = ""
    let servicesByCategory = {}
    let serviceCount = 0
    for (const key in services) {
        let service = services[key]
        let category = service["category"]
        //console.log("service ID " + key + " in " +category+ " called "+ service["service"]) 
        if (!SettingsData.serviceCategories.includes(category)) {
            ClientData.log("CD",280,"service ID " + key + " has undeclared category " + category + ", skipping.")
            continue
        }
        service["serviceKey"] = key
        if (!servicesByCategory.hasOwnProperty(category)) {
            servicesByCategory[category] = []
        }
        let sbc = servicesByCategory[category]
        sbc.push(service)
        servicesByCategory[category] = sbc        
    }
    //sort the services alphabetically.
    for (const category in servicesByCategory)
    {
        let services = servicesByCategory[category]
        serviceCount += (Object.keys(services));
        //console.log("> ["+category+"] " + JSON.stringify(services,null,3))
        services = Utils.sortArrayByKey(services,"service") 
        //console.log("< ["+category+"] " + JSON.stringify(services,null,3))
        servicesByCategory[category] = services
    }
    if (0===serviceCount) {
        ClientData.ServiceTrace += "\nCD.sSBC (0===serviceCount)"
        SessionData.loadServicesFailed = true
    }

    SettingsData.servicesByCategory = servicesByCategory
    //console.log("servicesByCategory: " + JSON.stringify(servicesByCategory,null,3))
    let categoriesWithServices = []
    for (const property in servicesByCategory)
    {
        categoriesWithServices.push(property)
    }
    let orderedUsedCategories = []
    SettingsData.serviceCategories.forEach ((categoryName) => {
        if (categoriesWithServices.includes(categoryName)) {
            orderedUsedCategories.push(categoryName)
            //console.log("pushing cat: "+categoryName)
        }
    })
    SettingsData.serviceCategories = orderedUsedCategories
    ClientData.ServiceTrace += ("\nsortServicesByCategory--count " + SettingsData.serviceCategories.length)
    return serviceSortErrorMessage
}

ClientData.toggleCategoryDisplay = (categoryName,renderUi) => {
    //console.log("ClientData.toggleCategoryDisplay("+categoryName+",...)")
    if (!Geolocate.haveCurrent) { 
        Geolocate.initialize()
    }
    let categoryShownIndex = SessionData.openCategories.indexOf(categoryName)
    if (-1 ===  categoryShownIndex) {
        SessionData.openCategories.push(categoryName)
    }
    else {
        SessionData.openCategories.splice(categoryShownIndex,1)
    }
    renderUi()
}

export default ClientData;