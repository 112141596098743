import React from 'react';
import DiversionItem from './DiversionItem';

// global data
import DiversionData  from './DiversionData';

export default class DiversionListItem extends React.Component {
    constructor(props){
        super()
    }

    render () {
        let diversionText = DiversionData.diversionTitles[this.props.index]
        //console.log("DLI.render() index: " +this.props.index +", " + diversionText)
        return ( <DiversionItem  index={this.props.index} resetNext={this.props.resetNext} diversionText={diversionText}  setScreen={this.props.setScreen}/> )
    }
}
