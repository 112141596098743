import React from "react"
import {Paper, List } from '@material-ui/core';
import CollapsableServiceTypeList from "./CollapsableServiceTypeList";

// global data
import SettingsData from "./SettingsData";
//import SessionData from "./SessionData";

export default function AllServiceTypesList(props){
    let serviceCategories = SettingsData.serviceCategories
    //console.log("AllServiceTypesList--categories " + JSON.stringify(serviceCategories,null,3))

    let haveDiversions = 0 < props.diversionTitles.length
    //console.log("haveDiversions: " + haveDiversions)
    if (haveDiversions) {
        return (
            <Paper style={{maxHeight: 600, overflow: 'auto'}}>
                <List> 
                    {[...Array(serviceCategories.length).keys()].map((index) => {
                        return (
                            <CollapsableServiceTypeList key={index} categoryIndex={index} servicesData={props.servicesData} setScreen={props.setScreen} />
                        )
                    })}
                </List>
                {/*<Box mt={-2}>
                    <ListItem key='divertableOffenses' button 
                        onClick={() => { 
                            goToDiversion()
                        }}>
                        <ListItemText 
                            secondary={ 
                                <Grid container component={'span'} direction='row' alignItems='center'>
                                    <Typography component={'span'} variant="h6" color="error" >
                                        Diversion Options
                                    </Typography>
                                    <Box component={'span'} ml= {2} >
                                    </Box>
                                </Grid>
                            }
                        />
                        <RightArrow  color='error'/>
                    </ListItem>
                        </Box> */}
            </Paper>
        )
    }
    else {
        return (
            <Paper style={{maxHeight: 600, overflow: 'auto'}}>
                <List> 
                    {[...Array(serviceCategories.length).keys()].map((index) => {
                        return (
                            <CollapsableServiceTypeList key={index} categoryIndex={index} servicesData={props.servicesData} setScreen={props.setScreen} />
                        )
                    })}
                </List>
            </Paper>
        )
    } 
}
