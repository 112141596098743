import React from 'react';
import SfyHeader from "./SfyHeader"
import Format  from '../Utilities/Format';
import {Grid, TextField, Fab, Box, Icon, Button, Typography } from '@material-ui/core';

// global data
import DemoFrameText from './DemoFrameText';
import DiversionData  from './DiversionData';
import SettingsData  from './SettingsData';
import TransmitOnline from "./TransmitOnline";

export default class DiversionSendScreen extends React.Component {
    state = {
        emailText:"",
        muiText:"",
        canSend:(!SettingsData.inDemoMode),
    }
    
    async componentDidMount() {
        // FOR REVERSE GEOCODING  https://developers.google.com/maps/documentation/geocoding/requests-reverse-geocoding
        let emailData = this.formatRequestForEmail()
        this.setState({emailText:emailData})
        let muiData = this.formatRequestForMui()
        this.setState({muiText:muiData})
    }
    constructor(props){
        super()
        this.sendDiversionRequest = this.sendDiversionRequest.bind(this)
        this.formatRequestForMui = this.formatRequestForMui.bind(this)
        this.handleDiversionNote = this.handleDiversionNote.bind(this)
        this.formatRequestForEmail = this.formatRequestForEmail.bind(this)
        this.showDiversionLocationInMaps = this.showDiversionLocationInMaps.bind(this)
        this.haveDiversionMapLocation = this.haveDiversionMapLocation.bind(this)
        this.getLocationLinkHtml = this.getLocationLinkHtml.bind(this)
        this.adjustmentsForDemo = this.adjustmentsForDemo.bind(this)
        this.verifyEmail = this.verifyEmail.bind(this)
        this.canSendDR = this.canSendDR.bind(this)
    }

    showDiversionLocationInMaps () {
        let URL = "https://maps.google.com/?q=" +DiversionData.LatAtDiversionChoice+ "," + DiversionData.LongAtDiversionChoice
        window.open(URL)
    }

    haveDiversionMapLocation () {
        return ((0!==DiversionData.LatAtDiversionChoice)&&(0!==DiversionData.LongAtDiversionChoice))
    }

    getLocationLinkHtml () {
        let locationInfo = ""
        if (0===(DiversionData.LatAtDiversionChoice + DiversionData.LongAtDiversionChoice)) {
            locationInfo = " (the location was not captured for this diversion request)"
        }
        else {
            // encodeing details https://www.w3schools.com/tags/ref_urlencode.asp
            let mapString = "https://maps.google.com/%3fq=" +DiversionData.LatAtDiversionChoice+ "%2C" + DiversionData.LongAtDiversionChoice
            let encodedLink = "%3ca href="+mapString+"%3eclick to see the location in the map%3c%2fa%3e"
            locationInfo = encodedLink
        }
        //console.log("getLocationLinkHtml: " + locationInfo)
        return locationInfo
    }

    formatRequestForMui () {
        if (0===(DiversionData.LatAtDiversionChoice + DiversionData.LongAtDiversionChoice)) {
            DiversionData.GeoCoordinates = " (the location was not captured for this diversion request)"
        }
        else {
            DiversionData.GeoCoordinates = " (at the location linked above)"
        }
        Format.setYouthContactMap()

        let testText = 
        (SettingsData.inDemoMode?(DemoFrameText.drDemoOverview + DemoFrameText.Review + "\n\n" + 
            DemoFrameText.installYsaLink +"\n\n" +
            DemoFrameText.drReality +"\n\n" +
            DemoFrameText.snip +"\n"
         ) 
        :"") +
        "I encountered " + DiversionData.YouthName +
        " on " + DiversionData.DiversionTime +
        DiversionData.GeoCoordinates +
        ", and observed and recorded the following divertable offense" +
        ((1<DiversionData.selectedDiversions.length)?"s: ":": ")
        DiversionData.selectedDiversions.forEach(index => {
            let diversion = DiversionData.diversionTitles[index]
          testText += "\n - " + diversion
        })
    
        //console.log("YIC 1 MUI: "+ JSON.stringify(DiversionData.YouthIsContact))
        let ContactIdentifier1a = "contact named " +DiversionData.Contact1Name
        let ContactIdentifier1b = DiversionData.Contact1Name
        let ContactIdentifier1c = DiversionData.Contact1Name

        if (DiversionData.YouthIsContact.includes(1)) {
            ContactIdentifier1a = "contact: his/her self"
            ContactIdentifier1b = "he/she"
            ContactIdentifier1c = "his/her self"
        }

        testText += "\n\n" + DiversionData.DivertedFirstName +
        " provided the following date of birth: " +DiversionData.DivertedDoB +
        ", and a guardian or caretaker " +
        ContactIdentifier1a + ".\n\n" 
        testText +=  DiversionData.DivertedFirstName + " reports that "+ ContactIdentifier1b + " is reachable by phone at " +  
        DiversionData.Contact1Phone 
        if (DiversionData.HaveValid1Email && (0!==DiversionData.Contact1Email.length)) {
           testText += ", and by email at the following address: " + DiversionData.Contact1Email
        }
        if (0 < DiversionData.Contact1Note.length) {
            testText += ". " + DiversionData.DivertedFirstName + " provided this additional note regarding " + ContactIdentifier1c +
            ": \"" + DiversionData.Contact1Note + "\"\n\n"
        }
        else {
            testText += ".\n\n"
        }
        let hasContact2 =  (!DiversionData.IgnoreContact2 && 
                            (1 < DiversionData.Contact2Name.length) && 
                            DiversionData.HaveValid2Phone)
        let hasContact3 =  (!DiversionData.IgnoreContact3 && 
                            (1 < DiversionData.Contact3Name.length) && 
                            DiversionData.HaveValid3Phone)
        let extraContacts = 0
        if (hasContact2) {
            extraContacts++
        }    
        if (hasContact3) {
            extraContacts++
        }      
        if (0<extraContacts) {
            testText += DiversionData.DivertedFirstName + " provided "
            if (1===extraContacts) {
                testText += "one additional contact:"
            }
            else if (2===extraContacts) {
                testText += "two additional contacts:"
            }
            testText += "\n\n"
            if (hasContact2) {
                let detail = ""
                //console.log("YIC 2 MUI: "+ JSON.stringify(DiversionData.YouthIsContact))
                if (DiversionData.YouthIsContact.includes(2)) {
                    detail = "(his/her self) "
                }
                testText += "    - Name:  " + detail + DiversionData.Contact2Name + "\n" +
                            "    - Phone: " + DiversionData.Contact2Phone
                if (DiversionData.HaveValid2Email && (0!==DiversionData.Contact2Email.length)) {
                    testText += "\n    - Email: " + DiversionData.Contact2Email 
                }
                if (0 < DiversionData.Contact2Note.length) {
                    testText += "\n    - Note: \"" + DiversionData.Contact2Note + "\""
                }
                testText += "\n\n"
            }
            if (hasContact3) {
                let detail = ""
                //console.log("YIC 3 MUI: "+ JSON.stringify(DiversionData.YouthIsContact))
                if (DiversionData.YouthIsContact.includes(3)) {
                    detail = "(his/her self) "
                }
                testText += "    - Name:  " + detail + DiversionData.Contact3Name + "\n" +
                            "    - Phone: " + DiversionData.Contact3Phone
                if (DiversionData.HaveValid3Email && (0!==DiversionData.Contact3Email.length)) {
                    testText += "\n    - Email: " + DiversionData.Contact3Email 
                }
                if (0 < DiversionData.Contact3Note.length) {
                    testText += "\n    - Note:  \"" + DiversionData.Contact3Note + "\""
                }
            }
        }
        testText += "\n--From " + SettingsData.getActualEmailAddress()
        if (SettingsData.inDemoMode) {
            testText += "\n"+DemoFrameText.snip + "\n\n" 
            testText += DemoFrameText.aboutSfyLink + "\n"
        }
        
        return testText
    }

    formatRequestForEmail () {
        if (0===(DiversionData.LatAtDiversionChoice + DiversionData.LongAtDiversionChoice)) {
            DiversionData.GeoCoordinates = " (the location was not captured for this diversion request)"
        }
        else {
            DiversionData.GeoCoordinates = " (" + this.getLocationLinkHtml() + ")"
        }
        Format.setYouthContactMap()
        let testText = 
        (SettingsData.inDemoMode?(  "<p>" + DemoFrameText.drDemoOverview + DemoFrameText.Sent+ SettingsData.getActualEmailAddress() + "</p>" + 
                                    "<p>" + DemoFrameText.installYsaLink +"</p>" +
                                    "<p>" + DemoFrameText.drReality +"</p>" +
                                    "<p></p>" +
                                    "<p>" +DemoFrameText.snip +"</p>"
                                 ) 
                                :"") +
        "<p>I encountered " + DiversionData.YouthName +
        " on " + DiversionData.DiversionTime +
        DiversionData.GeoCoordinates +
        ", and observed and recorded the following divertable offense" +
        ((1<DiversionData.selectedDiversions.length)?"s: ":": ") + "<ul>"
        DiversionData.selectedDiversions.forEach(index => {
            let diversion = DiversionData.diversionTitles[index]
          testText += "<li>" + diversion + "</li>"
        })
        testText += "</ul></p>"

        let ContactIdentifier1 = " named " + DiversionData.Contact1Name
        let ContactIdentifier1d = DiversionData.Contact1Name
        let ContactIdentifier1e = DiversionData.Contact1Name
        //console.log("YIC 1 Email: "+ JSON.stringify(DiversionData.YouthIsContact))     
        if (DiversionData.YouthIsContact.includes(1)) {
            ContactIdentifier1 = ": his/her self"
            ContactIdentifier1d = "his/her self"
            ContactIdentifier1e = "he/she"
        }
        testText += "<p>" + DiversionData.DivertedFirstName +
        " provided the following date of birth: " + DiversionData.DivertedDoB +
        ", and a guardian or caretaker" + ContactIdentifier1 + ".</p>" 
        testText +=  "<p>"+ DiversionData.DivertedFirstName + " reports that "+ ContactIdentifier1e + " is reachable by phone at " +  
        DiversionData.Contact1Phone 
        if (DiversionData.HaveValid1Email && (0!==DiversionData.Contact1Email.length)) {
           testText += ", and by email at the following address: " +
           DiversionData.Contact1Email
        }
        if (0 < DiversionData.Contact1Note.length) {
            testText += ". " + DiversionData.DivertedFirstName + " provided this additional note regarding " + ContactIdentifier1d +
            ": <q>" + DiversionData.Contact1Note + "</q>"
        }
        testText += ".</p>"

        let hasContact2 =  (!DiversionData.IgnoreContact2 && 
            (1 < DiversionData.Contact2Name.length) && 
            DiversionData.HaveValid2Phone)
        let hasContact3 =  (!DiversionData.IgnoreContact3 && 
            (1 < DiversionData.Contact3Name.length) && 
            DiversionData.HaveValid3Phone)
            
        let extraContacts = 0
        if (hasContact2) {
            extraContacts++
        }    
        if (hasContact3) {
            extraContacts++
        }      
        if (0<extraContacts) {
            testText += "<p>"+ DiversionData.DivertedFirstName + " provided "
            if (1===extraContacts) {
                testText += "one additional contact:"
            }
            else if (2===extraContacts) {
                testText += "two additional contacts:"
            }
            testText += "</p>"

            if (hasContact2) {
                let detail = ""
                //console.log("YIC 2 Email: "+ JSON.stringify(DiversionData.YouthIsContact))     
                if (DiversionData.YouthIsContact.includes(2)) {
                    detail = "(his/her self) "
                }
                testText += "<p><ul>"
                testText += "<li><b>Name:</b>   " +detail+ DiversionData.Contact2Name + "</li>" +
                            "<li><b>Phone:</b>  " + DiversionData.Contact2Phone + "</li>"
                if (DiversionData.HaveValid2Email && (0!==DiversionData.Contact2Email.length)) {
                    testText += "<li><b>Email:</b> " + DiversionData.Contact2Email + "</li>"
                }
                if (0 < DiversionData.Contact2Note.length) {
                    testText += "<li><b>Note:</b>  <q>" + DiversionData.Contact2Note + "</q></li>"
                }
                testText += "</ul></p>"
            }

            if (hasContact3) {
                let detail = ""
                //console.log("YIC 3 Email: "+ JSON.stringify(DiversionData.YouthIsContact))     
                if (DiversionData.YouthIsContact.includes(3)) {
                    detail = "(his/her self) "
                }
                testText += "<p><ul>"
                testText += "<li><b>Name:</b>  " + detail+ DiversionData.Contact3Name + "</li>" +
                            "<li><b>Phone:</b> " + DiversionData.Contact3Phone + "</li>"
                if (DiversionData.HaveValid3Email && (0!==DiversionData.Contact3Email.length)) {
                    testText += "<li><b>Email:</b> " + DiversionData.Contact3Email  + "</li>"
                }
                if (0 < DiversionData.Contact3Note.length) {
                    testText += "<li><b>Note:</b>  <q>" + DiversionData.Contact3Note + "</q></li>"
                }
                testText += "</ul></p>"
            }
        }
        
        testText += "</p><p>--From " + SettingsData.getActualEmailAddress() + "</p>"
        if (SettingsData.inDemoMode) {
            testText += DemoFrameText.snip 
            testText += "<p>" +DemoFrameText.aboutSfyLink + "</p>"
        }
        
        return testText
    }     

    verifyEmail (event)  {
        let emailAddress = event.target.value
        SettingsData.demoEmailAddress = emailAddress
        let emailOkay =  Format.validEmailAddress(emailAddress)
        //console.log("emailAddress: " + emailAddress + ",emailOkay: " +emailOkay)
        this.setState({canSend:emailOkay})
        if (!emailOkay) {
            console.log(emailAddress + ' is not in a valid email format.')
        }
    }
    
    canSendDR () {
        if (SettingsData.inDemoMode) {
            //console.log("canSendDR--canSend: "+ this.state.canSend + "to email: " + SettingsData.demoEmailAddress)
            return this.state.canSend
        }
        console.log("canSendDR--NOT inDemoMode")
        return true
    }

    sendDiversionRequest () {
        let offenseData = '<html><body>'+((0 < DiversionData.DiversionNote.length)?('<p>'+DiversionData.DiversionNote + '</p>'):'') + this.state.emailText + '</body></html>'
        //console.log("offenseData: " + offenseData)
        let demoEmailTarget = SettingsData.demoEmailAddress
        //console.log("demoEmailTarget: " + demoEmailTarget)
        ///////////////////////////////////////////////////////////////////////////
        // The tempSender and tempData are needed for verification of the monthly
        // reports on diversion request stats.  Once the monthly reports are 
        // verified these temp* variables can be removed all the way to the server.
        let tempSender =  SettingsData.getActualEmailAddress()
        let tempData = ""
        DiversionData.selectedDiversions.forEach(index => {
            tempData +=  DiversionData.diversionTitles[index] + ", "
        })
        ///////////////////////////////////////////////////////////////////////////
        
        // demo and tester users override the diversion local director as the DR email target
        let emailTarget = SettingsData.diversionServicesDir
        if (SettingsData.globalTester) {
            emailTarget = SettingsData.rawEmailAddress
        } 
        else if (SettingsData.inDemoMode) {
            emailTarget = demoEmailTarget
            //console.log("inDemoMode--demoEmailTarget: " + demoEmailTarget +", emailTarget: "+emailTarget)
        }
        //console.log("emailTarget: " + emailTarget)

        TransmitOnline.reportDiversionRequest(emailTarget, (SettingsData.inDemoMode?"true":"false"), offenseData, tempSender, tempData)
        if (DiversionData.ClearDiversion) {
            DiversionData.ClearDiversionData()
        }
        SettingsData.demoEmailAddress = ""
        this.props.setScreen('find-services')
    }

    handleDiversionNote  (event) {
        DiversionData.DiversionNote  = event.target.value
    }

    adjustmentsForDemo() {
        //console.log("SD.inDemoMode: " + SettingsData.inDemoMode)
        if (SettingsData.inDemoMode) {
            return (
                <div> 
                    <Box mt={2}>  
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="button" color="primary">EMAIL TO:</Typography>
                            </Grid>
                            <Grid item xs>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    color='primary' 
                                    placeholder={"Enter email address"}
                                    defaultValue={SettingsData.demoEmailAddress}
                                    onChange ={this.verifyEmail}
                                />
                            </Grid>
                        </Grid>
                    </Box> 
                </div>
            )
        }
        else {
            let emailTitle = "Email to DSD: " + SettingsData.diversionServicesDir
            if (SettingsData.globalTester) {
                emailTitle = "Email to: " + SettingsData.getActualEmailAddress()
            }
            return (
                <div> 
                    <Box mb={-2}> 
                        <p style={{color:'#367BBD'}}>{emailTitle}</p>
                    </Box>
                </div>
            )
        } 
    }

    render () {
        //console.log("DSS.render() "  + SettingsData.diversionServicesDir + ", " +SettingsData.getActualEmailAddress())
        let canShowInMap = this.haveDiversionMapLocation()
        let noteRows = SettingsData.inDemoMode?3:4
        let drRows = SettingsData.inDemoMode?16:17
        let sendable = this.canSendDR()
        //console.log("sendable: "+sendable)
        return ( 
            <div>
                <SfyHeader context='SFY' screenId={'diversion-send-screen'}  lastScreenId={this.props.lastScreenId}  setScreen={this.props.setScreen}  Settings={true}/>
                <div>
                    <Box ml= {3} mr={3}>
                        <Grid container direction='column' spacing={1}>
                            {this.adjustmentsForDemo()}
                            <Box mt={2}> 
                                <TextField
                                    id="personal note"
                                    defaultValue={DiversionData.DiversionNote}
                                    fullWidth={true}
                                    onChange ={this.handleDiversionNote}
                                    placeholder={"Additional diversion information from sender."}
                                    color='primary'
                                    multiline
                                    minRows={noteRows} 
                                    maxRows={noteRows} 
                                    variant='outlined'                       
                                />
                            </Box>
                            <Box color='#367BBD' mb={0} style={{  backgroundColor: '#ffffff' , height: '7vh' }} >
                                <Button
                                    onClick={() => { 
                                        this.showDiversionLocationInMaps()
                                    }}
                                    style={{ color: (canShowInMap?'#367BBD':'#AAAAAA')}}
                                    size='small'
                                    disabled={!canShowInMap}
                                >  
                                    {"See the offense location on a map"}
                                </Button>
                            </Box>  
                            <Box mt={-3}> 
                                <TextField
                                    id="email draft"
                                    defaultValue={this.state.muiText}
                                    fullWidth={true}
                                    color='primary'
                                    inputProps={{ readOnly: true }} 
                                    multiline
                                    minRows={drRows} 
                                    maxRows={drRows} 
                                    variant='outlined'                       
                                />
                            </Box>
                            <Box ml={1} mt={1}> 
                                <Fab color='secondary' variant="extended" disabled={!sendable} onClick={this.sendDiversionRequest}>
                                    Send
                                    <Icon>send</Icon> 
                                </Fab>
                            </Box>
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    }
}
