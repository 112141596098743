const ServerData = {}

let useProductionServer = true // false uses staging
ServerData.getServerRootUrl = () => {
   if(useProductionServer){
      return "https://sfy-server.herokuapp.com/"
   }
   else {
      return "https://desolate-garden-75313.herokuapp.com/"
   }
}


export default ServerData;