import SettingsData from "./SettingsData"

const DemoFrameText = {}
// For demo mode, wrap the SendServiceData.generatedText with:
// - a description of what is happening in the demo
// - if they are sending the info to themselves acknowledge it and make sure the
//   get the full picture of the value to their role. 
// - if they are sending to someone else state that and provide the link to how to
//   to install the app for themselves.
// - Encourage them to make the best use of it.

DemoFrameText.drDemoOverview = "This is the demo version of a diversion request from the Strategies for Youth, Youth Services App (YSApp). "
DemoFrameText.drDemoOverview += "The demo has extra information that is not in the normal diversion request. "
DemoFrameText.drDemoOverview += "A normal diversion request always goes to the department's diversion officer"
DemoFrameText.Review = ", but you can send this demo version to a friend, collegue, or yourself, by filling in the 'EMAIL TO:' box above."
DemoFrameText.Sent = ", but this demo version was shared with you by "
DemoFrameText.intro = "This email is from the demo version of the Strategies for Youth, Youth Services App (YSApp)."
DemoFrameText.cta1 = "Please send this email to interested collegues."
DemoFrameText.serviceReality = "This demo shows the service information that YSApp users might text or email to youth and guardians, bracketed by  '--snip--' below. "
DemoFrameText.drReality = "This demo shows a diversion request that YSApp users might email to the department's diversion officer, bracketed by '--snip--' below. "
//"Right now, if you were serving an actual youth, the text below bracketed by '--snip--' would be sent alone, directly by text or email to the youth or guardian.  Since this is a demo, we send additional infomation."
DemoFrameText.installYsaLink = "Learn to install, register, and use the YSApp at this link: https://strategiesforyouth.org/tools/youth-services-app/instructions/"
//"%3ca href=https://strategiesforyouth.org/tools/youth-services-app/instructions/%3eLearn to install the YSA at this link.%3c%2fa%3e"

DemoFrameText.snip = "--snip--" 
DemoFrameText.aboutSfyLink = "Learn about Strategies for Youth at this link: https://strategiesforyouth.org"
//"%3ca href=https://strategiesforyouth.org%3eLearn about Strategies for Youth at this link.%3c%2fa%3e"

////////////////////////
DemoFrameText.policeChiefServicesPitch = "As a chief you have an opportunity to multiply the impact of your team by steering their efforts away from the complexities of the costly and overloaded legal system toward existing services when appropriate."
DemoFrameText.sheriffServicesPitch = "As a sheriff, you can have an impact on how youth are managed in your jurisdiction.  Providing existing needed social services can make a huge difference in th outcomes for a youth and can save the jurisdiction money in managing complex and often poorly matched legal actions."
DemoFrameText.leoServicesPitch = "As a police officer interacting with a youth, you have an opportunity to help the youth get beyond their current situation with existing services and at the same time save your jurisdiction time and money for more demanding work."
DemoFrameText.sheriffDeputyServicesPitch = "As a sheriff's deputy, you can have an inpact on how youth are managed in your jurisdiction. Providing existing needed social services can make a huge difference in th outcomes for a youth and can save the jurisdiction money in managing complex and often poorly matched legal actions."
DemoFrameText.probationOfficerServicesPitch = "As a probation officer you can apply your detailed knowledge of each youth toward getting them the help they need to rise above their current dificulties."
DemoFrameText.jdaiCoordinatorServicesPitch = "As a JDAI coordinator YSApp will help focus your efforts providing alternativ solutions to youth in dificulties."
DemoFrameText.socialWorkerServicesPitch = "As a social worker you can apply your detailed knowledge of each youth toward getting them the help they need to rise above their current dificulties."
DemoFrameText.judgeServicesPitch = "As a judge, you can have an outsized inpact on how youth are managed in your jurisdiction.  Providing existing needed social services can make a huge difference in the outcomes for a youth and can save the jurisdiction money in managing complex and often unnecessary legal actions."
DemoFrameText.prosecutorServicesPitch = "As a prosecutor, the YSApp can lower demands for dealing with many cases." 
DemoFrameText.juvenileDefenderServicesPitch = "As a juvenile defender, the YSApp provides better alternatives for dealing with many cases." 
DemoFrameText.generalServicesPitch = "Regardless of your role in society, understanding the Youth Services App will enable you to advocate for helping troubled youth rise above current dificulties using local services."
////////////////////////

DemoFrameText.getServicePitch = (role) => {
    //console.log("Role: ", role)
    let servicesPitch = DemoFrameText.generalServicesPitch
    switch (SettingsData.LawEnforcementRole) {
      case "PoliceChief":
        servicesPitch = DemoFrameText.policeChiefServicesPitch
      break
      
      case "Sheriff":
        servicesPitch = DemoFrameText.sheriffServicesPitch
      break

      case "LEO":
        servicesPitch = DemoFrameText.leoServicesPitch
      break

      case "SheriffDeputy":
        servicesPitch = DemoFrameText.sheriffDeputyServicesPitch
      break

      case "ProbationOfficer":
        servicesPitch = DemoFrameText.probationOfficerServicesPitch
      break

      case "JdaiCoord":
        servicesPitch = DemoFrameText.jdaiCoordinatorServicesPitch
      break

      case "SocialWorker":
        servicesPitch = DemoFrameText.socialWorkerServicesPitch
      break

      case "Judge":
        servicesPitch = DemoFrameText.judgeServicesPitch
      break

      case "Prosecutor":
        servicesPitch = DemoFrameText.prosecutorServicesPitch
      break

      case "JuvenileDefender":
        servicesPitch = DemoFrameText.juvenileDefenderServicesPitch
      break

      case "Other":
      case "empty":
      default:
      break
    }
    //console.log("servicesPitch: "+servicesPitch)
    return servicesPitch 
}

export default DemoFrameText;