import React from 'react';
import SfyHeader from "./SfyHeader"
import SwitchScreen from "./SwitchScreen";
import { Box, Grid } from '@material-ui/core';

// Global Data
import RegistrationData from "./RegistrationData";
import SettingsData from "./SettingsData";
import ServerData from "./ServerData";

class RegisterErrorScreen extends React.Component
{
    state = {
        checkUserAndDepartment: (RegistrationData.canRegister && !RegistrationData.userAndDepartmentAccepted),
        loadingUser: false,
        loadingVerificationCode: false,
        checkFailed: false,
        mounted: false,
        errorMessage: ""
    }

    constructor(props) {
        super()
    }

    async componentDidMount() {
        //console.log("##### accepted: "+RegistrationData.userAndDepartmentAccepted +",  verified: "+SettingsData.verified)
        // came from the registration screen
        if (this.state.checkUserAndDepartment) {
            //console.log("In checkUserAndDepartment--PD and USER")
            // if successful goes to verification screen otherwise displays error screen
            let accepted = false
            try {
                this.setState({loadingUser: true })
                const url = (ServerData.getServerRootUrl()   + "getUser?email="+ (SettingsData.getActualEmailAddress()).toLowerCase()).replace(/ /g,'%20')
                console.log("URL: " + url)
                const response = await fetch(url)
                const data = await response.text()
                this.setState({loadingUser: false })
                if (!this.state.loadingUser) {
                    let dataObject = (JSON.parse(data))
                    SettingsData.user = dataObject
                    //SettingsData.CacheLocally('user',dataObject)
                    window.localStorage.setItem('user', data)
                    console.log("SettingsData.user "+ JSON.stringify(SettingsData.user,null,3))
                    accepted =  ("{}" !== data) 
                }
            }
            catch (error) {
                accepted = false
                let message = "RegisterErrorScreen failed to accept the email: "+error
                console.log("error on get user "+message)
                alert(message) 
            }
            if (!accepted) {
                let errorMessage = "The email adress you entered (" + SettingsData.getActualEmailAddress() + ") is not an authorized user in the  " + SettingsData.policeDepartment['agency']
                console.log("!accepted " + errorMessage)
                this.setState({errorMessage: errorMessage}) 
            }

            // if the user and department are accepted get the verification code, and trigger the email to SettingsData.actualEmailAddress()
            if (accepted) { 
                try {
                    this.setState({loadingVerificationCode: true })
                    const url = (ServerData.getServerRootUrl()   + "getVerificationCode?email="+ SettingsData.getActualEmailAddress()).replace(/ /g,'%20')
                    console.log("verificationCode URL: " + url)
                    const response = await fetch(url)
                    const data = await response.text()
                    console.log("verificationCode data: " + data)
                    this.setState({loadingVerificationCode: false })
                    if (!this.state.loadingVerificationCode) {
                        RegistrationData.verificationCodeFromServer = data
                        let gotVerificationCode = (9===data.length)
                        accepted = gotVerificationCode
                    }
                }
                catch (error) {
                    accepted = false
                    let message = "ErrorScreen -- failed to get verification code from the server: "+error
                    console.log("error on getVerificationCode "+message)
                    alert(message)
                }
                if (!accepted) {
                    let errorMessage = "Unable to get a verification code for " + SettingsData.getActualEmailAddress() + " from the server."
                    this.setState({errorMessage: errorMessage}) 
                }
            }
            let nextscreen = accepted?"verification-screen":"registration-screen"
            this.setState({nextScreen: nextscreen})
            RegistrationData.userAndDepartmentAccepted = accepted
            this.setState({checkFailed: !accepted})
        }
        this.setState({mounted: true})
    }

    render () {
        //console.log("ES.render()" + this.state.nextScreen )
        if (!this.state.mounted)
        {
            return <div></div>
        }
        // if the current check failed, display the ErrorScreen with appropriate info, otherwise switch to the next screen 
        if (this.state.checkFailed && this.state.checkUserAndDepartment) {
            //console.log("In ErrorScreen.render() checkUserAndDepartment with error: " + this.state.errorMessage)
            return (
                <Grid container direction='column' spacing={3}>
                    <Grid>
                        <SfyHeader screenId={'register-error-screen'}  lastScreenId={'register-screen'} setScreen={this.props.setScreen}  Settings={false} />
                    </Grid>
                    <Box ml={3} mt={4} mr={3} >
                        <Grid item>
                            {this.state.errorMessage}
                        </Grid>
                    </Box>
                </Grid>
            )
        }
        else {
            //console.log("ERROR SCREEN CHECK OKAY" + this.state.nextScreen )
            return (
                <div>
                    <SwitchScreen screen={this.state.nextScreen} setScreen={this.props.setScreen} />
                </div>
            )
        }
    }
}

export default RegisterErrorScreen
