import React from 'react';
import SfyHeader from "./SfyHeader"
import { Grid, Typography, Fab, Icon, Box, Paper, TextField,} from '@material-ui/core';
import SfyLogo from './images/SFY_logo.png'

// global data
import RegistrationData from "./RegistrationData";
import SettingsData from "./SettingsData";
import SessionData from "./SessionData";
import ServerData from "./ServerData";

const gotoWebsite = () => {
    window.open('https://strategiesforyouth.org')
}

export default class SettingsScreen extends React.Component {
    constructor(props){
        super()
        this.onRequestFeature = this.onRequestFeature.bind(this);
        this.onSubmitProblem  = this.onSubmitProblem.bind(this);
        this.handleLogout     = this.handleLogout.bind(this);
        this.reset            = this.reset.bind(this);
    }

    onRequestFeature() {
        //console.log("onSubmitProblem")
        SessionData.sendNotesReportObject = {}
        SessionData.sendNotesUiDescriptor = {
            noteType:'request',
            typeIntent: 'Request a feature that would improve YSA workflow. SFY will review these for updates.',
            screenId:'send-feature-request-screen',
            lastScreenId:'settings-screen' ,
            noteTitle: 'Describe your New Feature',
            placeholder:'Please describe the feature you imagine.  How does it help the YSA user? How do you access it?',
            sendButtonText:'Request' ,
        }
        this.props.setScreen('send-note-screen')
    }

    onSubmitProblem() {
        //console.log("onSubmitProblem")
        SessionData.sendNotesReportObject = {}
        SessionData.sendNotesUiDescriptor = {
            noteType:'problem',
            typeIntent: 'Report a problem when the YSA does not behave the way you expect. SFY will review these for updates.',
            screenId:'report-bug-screen' ,
            lastScreenId:'settings-screen' ,
            noteTitle: 'Problem Description',
            placeholder:'Please describe the problem you saw, the data and time, and what you were doing directly before it happened.',
            sendButtonText:'Report' ,
        }
        this.props.setScreen('send-note-screen')
    }

    handleLogout() {
        SettingsData.clearLocal()
        SettingsData.clearSession()
        RegistrationData.clearSession()
        SessionData.clearSession()
        this.props.setScreen('register-screen')
    }

    async componentDidMount() {
        //console.log("SS.componentDidMount(): "  + ((SessionData.sendingNote)?" ":" not") + " sending " + JSON.stringify(SessionData.sendNotesReportObject))
        if (SessionData.sendingNote)
        {
            var url = (ServerData.getServerRootUrl()   + 
                "postNote?department=" + SettingsData.policeDepartment['agency'] + 
                "&email=" + SettingsData.getActualEmailAddress() +
                "&noteType=" + SessionData.sendNotesReportObject.noteType +
                "&noteText=" + SessionData.sendNotesReportObject.noteText)
            url = url.replace(/ /g,'%20')

            //console.log(">>>>> componentDidMount: " + url)           
            const requestOptions = {
                method: 'POST',
                mode: 'no-cors',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(url, requestOptions)
            const data = await response.text()
            // TODO JCG FIX THIS - should provide error message
            console.log("status " + response.status + ", " + data)
            SessionData.sendingNote = false
        }
    }

    reset () {
        console.log(">>>>>> RELOADING <<<<<<<")
        this.props.setScreen('reset-app')
    }

    render () {
        let testingString = SettingsData.getTestingString()
        return (
            <div >
                <Grid container direction='column' alignItems='stretch' spacing={0} >
                    <SfyHeader screenId={'settings-screen'}  lastScreenId={this.props.lastScreenId} setScreen={this.props.setScreen}  Settings={false} />
                    <Paper style={{overflow: 'auto'}} elevation={0}>
                        <Box ml={2} mr={2}>
                            <Grid container direction='column' >
                                <Box ml={0} mr={0} mt={0} mb={1}> 
                                        <Typography component={'span'} variant="subtitle1" color='secondary' >
                                                If you change your department or email, remove your YSA account from this device then re-register YSA with the new information:
                                        </Typography>  
                                    </Box>
                                <Box mb={1}> 
                                    <TextField
                                        id="PD-text"
                                        label="Department:"
                                        color='primary'                                
                                        defaultValue= {' '+ (SettingsData.verified ? SettingsData.policeDepartment['agency'] : "(not yet verified)") }
                                        fullWidth = {true}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Box>
                                <Box mb={1} > 
                                    <TextField
                                        id="Email-text"
                                        label="Email:"
                                        defaultValue= {' '+ (SettingsData.verified ? (SettingsData.getActualEmailAddress() + testingString ): "(not yet verified)") }
                                        fullWidth =  {true}
                                        InputProps={{
                                            readOnly: true,
                                            shrink: 'true',
                                        }}
                                    />
                                </Box>
                            </Grid >
                        </Box>
                        <Box ml={2} mr={2} mt={1}>
                            <Grid container direction='column' spacing={2} >
                                <Grid item xs={12} sm={6}>
                                    <Fab color='secondary' variant="extended" onClick={this.handleLogout}>
                                        Remove Account
                                        <Icon>no_accounts_outlined</Icon> 
                                    </Fab>
                                </Grid> 
                            </Grid>
                        </Box>
                        <Box ml={2} mt={4}>
                            <Typography component={'span'} variant="subtitle1" color='secondary' >
                                Reload to get the latest updates:
                            </Typography>
                            <Fab color='secondary' variant="extended" disabled={false} onClick={this.reset}>
                                reload services
                                <Icon>refresh</Icon> 
                            </Fab>
                        </Box>
                        <Box ml={2} mr={0} mt={4} mb={0}> 
                            <Box ml={0} mr={0} mt={1} mb={0}>
                                <Grid item xs={12} sm={6}>
                                    <img src={SfyLogo} alt='SFY Logo' onClick={gotoWebsite} />
                                </Grid>
                            </Box>
                            <Typography component={'span'} variant="subtitle1" color='secondary' >
                                    Help improve the Youth Services App:
                            </Typography> 
                            <Box ml={0} mr={0} mt={1} mb={0}>
                                <Fab color='secondary' variant="extended" onClick={this.onSubmitProblem}>
                                    Report a Problem
                                    <Icon>report_problem_outlined</Icon> 
                                </Fab>
                            </Box>
                            <Box ml={0} mr={0} mt={1} mb={0}> 
                                <Fab color='secondary' variant="extended" onClick={this.onRequestFeature}>
                                    Request a Feature 
                                    <Icon>add_circle_outlined</Icon> 
                                </Fab>
                            </Box> 
                        </Box>
                    </Paper>
                </Grid>
            </div>
        )
    }
}