const UpdatingData = {}

// current location driven by Geolocate.js
UpdatingData.currentLongitude = 0
UpdatingData.currentLatitude = 0

// this is the raw data from which the closest office is determined
// as the mobile app moves through the geography
UpdatingData.locationDependentData = {}

// infrequent or excess services to be shown via some sort of "more" option
UpdatingData.rareServices = []


export default UpdatingData;