import React from "react"
import { List,} from '@material-ui/core'
import ServiceItem from "./ServiceItem"
import Utils from "../Utilities/Utils"
import SettingsData from "../components/SettingsData"

export default function ServiceTypeList(props) {
  let categoryName = props.serviceCategoryName
  let categoryServices =  SettingsData.servicesByCategory[categoryName]
  if(undefined===categoryServices) {
    Utils.warnDeveloper (true,"should not display ServiceTypeList unless categorysServices is defined" )
    return <div> </div>
  }
  else {
    return (
      <List component="div" disablePadding>
        {[...Array(categoryServices.length).keys()].map((index) => {
          let serviceKey = categoryServices[index]["serviceKey"]
          let serviceData =props.servicesData[serviceKey]
          serviceData["categoryFrom"] = categoryName
          let hide = serviceData["hide"]
          if (!hide) {
            return ( <ServiceItem  key={index} serviceData={serviceData}  serviceKey={serviceKey} setScreen={props.setScreen}/> )
          }
          else {
            return null
          }
        })}
      </List>
    )
  }
}  